import React from 'react';
import Plx from 'react-plx';

import styles from './styles.module.scss';

function buildData({ trigger }) {
  const data = [
    {
      start: trigger,
      duration: `100px`,
      properties: [
        {
          startValue: 0.5,
          endValue: 1,
          property: 'scale',
        },
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
    {
      start: trigger,
      startOffset: '75vh',
      duration: '500px',
      properties: [
        {
          startValue: 1,
          endValue: 0.2,
          property: 'scale',
        },
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
        },
      ],
    },
  ];
  return data;
}

function AgeText({
  age,
  trigger,
}: {
  age: number;
  trigger: String;
}): React.ReactElement {
  return (
    <Plx
      className={`${styles.ageBlockAge} ${styles[`ageBlockAge${age}`]}`}
      parallaxData={buildData({ trigger })}
    >
      <img
        className={`${age < 10 ? styles.ageImage : styles.ageImage2Digits} ${
          styles[`ageImage${age}`]
        }`}
        alt={`Age ${age}`}
        src={require(`../../assets/images/timeline/${age}@3x.png`).default}
      />
    </Plx>
  );
}

export default AgeText;
