import React, { useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  Box,
  BoxProps,
  PopoverProps,
} from '@chakra-ui/react';

import { ReactComponent as QuestionMarkIconSm } from '../../assets/images/icons/question-mark-small.svg';
import { ReactComponent as QuestionMarkIconMd } from '../../assets/images/icons/question-mark-med.svg';
import { ReactComponent as QuestionMarkIconLg } from '../../assets/images/icons/question-mark-large.svg';
import { trackTooltipClose, trackTooltipOpen } from '../../lib/analytics';

export interface InfoPopoverProps {
  popoverText?: string | React.ReactElement;
  questionMarkSize: 'sm' | 'md' | 'lg';
  boxProps?: BoxProps;
  popoverProps?: PopoverProps;
  analyticsSrc: string;
}

const InfoPopover = ({
  popoverText,
  questionMarkSize,
  analyticsSrc,
  boxProps,
  popoverProps,
}: InfoPopoverProps): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  // fix overflow bug with initial placement of popover
  const [hasOpened, setHasOpened] = useState(false);

  const onClick = () => {
    if (!isOpen) {
      trackTooltipOpen(analyticsSrc);
    } else {
      trackTooltipClose(analyticsSrc);
    }
    setIsOpen(!isOpen);
    if (!isOpen && !hasOpened) {
      setHasOpened(true);
    }
  };

  const QuestionMarkButton = () => {
    switch (questionMarkSize) {
      case 'sm': {
        return <QuestionMarkIconSm onClick={onClick} cursor="pointer" />;
      }
      case 'md': {
        return <QuestionMarkIconMd onClick={onClick} cursor="pointer" />;
      }
      case 'lg': {
        return <QuestionMarkIconLg onClick={onClick} cursor="pointer" />;
      }
      default: {
        return <QuestionMarkIconMd onClick={onClick} cursor="pointer" />;
      }
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      closeOnBlur={true}
      {...popoverProps}
      // onClose={() => setIsOpen(false)}
      // onOpen={() => setIsOpen(true)}
    >
      <PopoverTrigger>
        <Box {...boxProps}>{QuestionMarkButton()}</Box>
      </PopoverTrigger>
      <PopoverContent
        bgColor={'brand.grey50'}
        borderStyle="solid"
        borderColor="brand.grey50"
        dropShadow="brand.popoverShadow"
        boxShadow="0px 5px 55px rgba(0, 0, 0, 0.23)"
        backdropFilter="blur(6px)"
        borderRadius="20px"
        paddingX={isOpen || hasOpened ? '50px' : '0'}
        paddingY={isOpen || hasOpened ? '30px' : '0'}
        insetStart="-15px"
        width={isOpen || hasOpened ? '345px' : '0px'}
      >
        <PopoverBody
          padding="0px"
          lineHeight="26px"
          fontSize="21px"
          fontWeight="400"
        >
          {popoverText}
        </PopoverBody>
        <Text
          marginTop="30px"
          textAlign="center"
          textDecoration="underline"
          onClick={onClick}
          cursor="pointer"
        >
          Close
        </Text>
      </PopoverContent>
    </Popover>
  );
};

export default InfoPopover;
