import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ContainerComponent from '../../components/Container';
import Header from '../../layout/Header/Header';
import GreenGiftBox from '../../assets/images/Animated-Gift-Box.gif';
import { trackAssignModalClose } from '../../lib/analytics';
import { ParentType, selectParent } from '../../store/reducers/homeSlice';
import WealthieHelmet from '../../components/WealthieHelmet';
import { RootState } from '../../store/reducers';

interface InvestGiftCardScreenProps {}

const InvestGiftCardScreen: React.FunctionComponent<
  InvestGiftCardScreenProps
> = () => {
  const history = useHistory();
  const [isBackGoHome, setIsBackGoHome] = useState(false);
  const { childId } = useParams();

  const parent: ParentType = useSelector((state: RootState) =>
    selectParent(state),
  );

  const handleComplete = () => {
    trackAssignModalClose();
    // window.open(
    //   'https://join.cidirectinvesting.com/ci-di-wealthie/',
    //   'wealthie_invest',
    // );
    // if (parent.parentGiftCards && parent.parentGiftCards.length > 1) {
    //   history.push('/assign-gift-cards');
    // } else {
    //   history.push(`/child-timeline/${childId}`);
    // }
    history.push(`/child-timeline/${childId}`);
  };

  useEffect(() => {
    if (parent.parentGiftCards && parent.parentGiftCards.length > 1) {
      setIsBackGoHome(false);
    } else {
      setIsBackGoHome(true);
    }
  }, [parent.parentGiftCards]);

  return (
    <ContainerComponent>
      <WealthieHelmet title="Invest Card" />
      <Header showNav headerStyle="backArrow" isBackGoHome={isBackGoHome} />
      <Box display="flex" flexDir="column" alignItems="center">
        <Image src={GreenGiftBox} alt="Green Gift Box" maxWidth="274px" />
        <Heading as="h1" mt="10px" fontSize="largeText" lineHeight="title">
          Success!
        </Heading>
        <Box
          mt="10px"
          textAlign="center"
          fontSize="smallTitle"
          fontWeight="smallText"
          lineHeight="medText"
          maxWidth="245px"
        >
          <Text>Your kid&apos;s first gift card has been assigned.</Text>
          <br />
          <Text>
            Next up, invest it! &#40;The link to open their investment account
            is in your email.&#41;
          </Text>
          <br />
          <Text>But first, get a load Wealthie!</Text>
        </Box>
        <Button
          variant="action"
          colorScheme="accent"
          mt="20px"
          onClick={handleComplete}
        >
          VIEW TIMELINE
        </Button>
      </Box>
    </ContainerComponent>
  );
};

export default InvestGiftCardScreen;
