import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, HStack } from '@chakra-ui/layout';
import { Heading, Center, Text, Link } from '@chakra-ui/react';
import ReactJoyride, { STATUS, Step } from 'react-joyride';

import Header from '../../layout/Header/Header';
import AgeMenu from '../../layout/AgeMenu';
import WelcomeSlider from '../../layout/WelcomeSlider';
import Container from '../../components/Container';

// Tour Tool Tip Images
import Avatars from '../../assets/images/tourTooltips/avatars.png';
import GiftCards from '../../assets/images/tourTooltips/gift-cards.png';
import GameController from '../../assets/images/tourTooltips/game-controller.png';
import Calculator from '../../assets/images/tourTooltips/calculator.png';
import GraduationHat from '../../assets/images/tourTooltips/graduation-hat.png';

import {
  getParentProfile,
  selectParentGreeting,
  selectParent,
  selectGiftCards,
} from '../../store/reducers/homeSlice';
import { RootState } from '../../store/reducers';
import Divider from '../../components/Divider/Divider';
import SectionContainer from '../../components/SectionContainer';

import styles from './styles.module.scss';
import NotificationButton from '../../components/NotificationButton';
import WealthieHelmet from '../../components/WealthieHelmet';
import TourTooltip from '../../components/TourTooltip';
import TourTooltipImage from '../../components/TourTooltipImage';
import {
  handleCreateJoyrideCookies,
  hasCompletedTour,
} from '../../helpers/reactJoyride/joyrideCookies';
import { handleJoyrideCallbackAnalytics } from '../../helpers/reactJoyride/joyrideCallback';

const tourPageName = 'home';

const floaterProps = {};
//   styles: {
//     floater: {
//       backgroundColor: 'purple',
//       transitionProperty: 'opacity',
//       // transition: 'opacity 0.3s ease 0s, transform 0.0s ease 0s !important',
//     },
//   },
// };

const steps: Step[] = [
  {
    content: (
      <TourTooltipImage
        text="Welcome to your Family HQ! Let's walk through your timeline."
        src={Avatars}
        alt="Avatars"
        width="183px"
      />
    ),
    disableBeacon: true,
    target: '.greating-heading',
    styles: {
      spotlight: {
        borderRadius: 0,
      },
    },
    placement: 'top',
    offset: -20,
    floaterProps,
  },
  {
    content: (
      <TourTooltipImage
        text="Land here to assign a gift card to your child. Then jump over to your kid's timeline to see those cards in action!"
        src={GiftCards}
        alt="Gift Cards"
        width="159.08px"
      />
    ),
    target: '.kids-and-cards-button',
    disableBeacon: true,
    styles: {
      spotlight: {
        borderRadius: '30px',
        marginLeft: '-0px',
        maxWidth: '173px',
      },
    },
    placement: 'bottom-end',
    offset: 23,
    floaterProps,
  },
  {
    content: (
      <TourTooltipImage
        text="Each assigned gift card posts to your kid's timeline, where it can be played. The Timeline is full of fun financial literacy for kids at every age."
        src={GameController}
        alt="Game Controller"
        width="149px"
      />
    ),
    target: '.kids-and-cards-button',
    disableBeacon: true,
    styles: {
      spotlight: {
        borderRadius: '30px',
        marginLeft: '-0px',
        maxWidth: '173px',
      },
    },
    placement: 'bottom-end',
    offset: 23,
  },
  {
    content: (
      <TourTooltipImage
        text="Investments take you from Wealthie into to your Family Investment Account. This is where gift card money can invest and grow."
        src={Calculator}
        alt="Calculator"
        width="205px"
      />
    ),
    target: '.investments-button',
    disableBeacon: true,
    styles: {
      spotlight: {
        borderRadius: '30px',
        marginLeft: '-0px',
        width: '177px',
        maxWidth: '177px',
      },
    },
    placement: 'bottom-end',
  },
  {
    content: (
      <TourTooltipImage
        text="This is your cheat sheet! When it comes to raising financially savvy kids, here's what you need to know as they grow."
        src={GraduationHat}
        alt="Graduation Hat"
        width="114px"
      />
    ),
    target: '.age-menu',
    disableBeacon: true,
    spotlightPadding: 10,
    styles: {
      spotlight: {
        borderRadius: 0,
      },
    },
    isFixed: true,
    // placement: 'top',
    // offset: -40,
    disableScrolling: true,
  },
];

function ParentLandingPage(): React.ReactElement {
  const [isInvestmentsDisabled, setIsInvestmentsDisabled] =
    useState<boolean>(true);

  const history = useHistory();
  const dispatch = useDispatch();
  const greetingName = useSelector((state: RootState) =>
    selectParentGreeting(state),
  );
  const currentParent = useSelector((state: RootState) => selectParent(state));
  const giftcards = useSelector((state: RootState) => selectGiftCards(state));

  useEffect(() => {
    dispatch(getParentProfile());
  }, [dispatch]);

  const pageTopRef = useRef<HTMLDivElement | null>(null);
  const [isJoyrideFinished, setIsJoyrideFinished] = useState(
    hasCompletedTour(tourPageName),
  );

  // This use effect is for scrolling to top of screen after joyride is completed or initial load
  useEffect(() => {
    if (isJoyrideFinished) {
      pageTopRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isJoyrideFinished]);

  useEffect(() => {
    if (currentParent?.children?.length === 0 || giftcards.length === 0) {
      setIsInvestmentsDisabled(true);
    } else {
      setIsInvestmentsDisabled(false);
    }
  }, [currentParent?.children, giftcards]);

  // const onClickLoginInvest = () => {
  //   window.open('https://wealthie.works');
  // };

  const onClickManageKids = () => {
    history.push('/my-kids');
  };

  return (
    <Container data-testid="parent-landing-page">
      <WealthieHelmet title="Home" />
      <Box className="greating-heading" ref={pageTopRef}>
        <Header headerStyle="hamburger" showNav />
        <SectionContainer>
          <Center paddingBottom="20px">
            <Heading variant="title">Hi {greetingName}!</Heading>
          </Center>
        </SectionContainer>
      </Box>
      <SectionContainer>
        <HStack
          my={['20px', '30px']}
          justifyContent="center"
          alignItems="flex-start"
          height={['', '200px']}
        >
          <WelcomeSlider className={styles.slider} autoPlay />
          <Box width="180px">
            <NotificationButton
              data-testid="kids-and-cards-button"
              notificationType={
                currentParent &&
                currentParent.parentGiftCards &&
                currentParent.parentGiftCards?.length > 0
                  ? 'number'
                  : 'none'
              }
              numberOfNotifications={currentParent.parentGiftCards?.length}
              buttonProps={{
                onClick: onClickManageKids,
                variant: 'infoAction',
                colorScheme: 'accent',
                border: '0px',
                marginBottom: ['20px', '30px'],
                className: 'kids-and-cards-button',
              }}
            >
              KIDS + CARDS
            </NotificationButton>
            <NotificationButton
              data-testid="investments-button"
              notificationType="tooltip"
              popoverProps={{
                offset: [15, 10],
                placement: 'bottom-end',
              }}
              popoverText={
                isInvestmentsDisabled ? (
                  <>
                    <Text>
                      Click here to open your child&apos;s investment account,
                      or visit their portfolio.
                    </Text>
                    <Text marginTop="30px">
                      This button will activate once you&apos;ve set up your
                      child&apos;s profile at KIDS + CARDS.
                    </Text>
                  </>
                ) : (
                  <>
                    <Text>Click here to access your investment portal.</Text>
                  </>
                )
              }
              buttonProps={
                isInvestmentsDisabled
                  ? {
                      disabled: isInvestmentsDisabled,
                      variant: 'infoAction',
                      border: '0px',
                      textDecoration: 'none',
                      sx: {
                        '&:hover': {
                          textDecoration: 'none',
                        },
                      },
                      className: 'investments-button',
                    }
                  : {
                      disabled: isInvestmentsDisabled,
                      variant: 'infoAction',
                      border: '0px',
                      as: Link,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      href: 'https://join.cidirectinvesting.com/ci-di-wealthie/',
                      target: '_investments',
                      textDecoration: 'none',
                      sx: {
                        '&:hover': {
                          textDecoration: 'none',
                        },
                      },
                      className: 'investments-button',
                    }
              }
            >
              INVESTMENTS
            </NotificationButton>
          </Box>
        </HStack>
        <Divider longer />
        <Box className="age-menu">
          <Heading variant="subTitle" className="age-menu-title">
            Financial Literacy by Age
          </Heading>
          <AgeMenu />
        </Box>
      </SectionContainer>
      {!hasCompletedTour(tourPageName) && (
        <ReactJoyride
          steps={steps}
          continuous
          run
          tooltipComponent={TourTooltip}
          scrollDuration={750}
          disableScrolling
          callback={(data) => {
            handleJoyrideCallbackAnalytics(data, tourPageName);
            handleCreateJoyrideCookies(data, tourPageName);
            const { status } = data;
            if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
              setIsJoyrideFinished(true);
            }
          }}
          spotlightPadding={0}
          styles={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
            },
            tooltipContainer: {},
            options: {},
            spotlight: {
              backgroundColor: '#999',
            },
          }}
          disableOverlayClose
        />
      )}
    </Container>
  );
}

export default ParentLandingPage;
