// import { notification } from 'antd';
// import { ArgsProps } from 'antd/lib/notification';
import { createStandaloneToast } from '@chakra-ui/react';

export const SUCCESS_TYPE = 'success';
export const INFO_TYPE = 'info';
export const WARNING_TYPE = 'warning';
export const ERROR_TYPE = 'error';

export const Messages = {
  somethingWentWrong: 'Something went wrong. Please try again shortly.',
  registeredSuccessfully: 'Successfully registered.',
  updatedAccountSuccessfully: 'Successfully updated account.',
  invalidFields: 'Please ensure that all fields are valid.',
  passwordResetSuccessfully: 'Password was successfully reset.',
  allFieldsRequired: 'All fields are required.',
  verificationFailed: 'Verification failed. Please try again shortly.',
  verificationSucceeded: 'Verification succeeded.',
};

const typeMapper = {
  [SUCCESS_TYPE]: 'Success',
  [INFO_TYPE]: 'Info',
  [WARNING_TYPE]: 'Warning',
  [ERROR_TYPE]: 'Error',
};

const statusTypeMapper = {
  [SUCCESS_TYPE]: 'success',
  [INFO_TYPE]: 'info',
  [WARNING_TYPE]: 'warning',
  [ERROR_TYPE]: 'error',
};

// export function showNotification({
//   type = INFO_TYPE,
//   message: content = '',
//   top = 70,
//   ...options
// }: ArgsProps): void {
//   return notification[type]({
//     message: typeMapper[type],
//     description: content,
//     duration: 3,
//     top,
//     ...options,
//   });
// }

// export default showNotification;

export function showNotification({
  type,
  message,
  showTitle = true,
}: {
  type: string;
  message: string;
  showTitle?: boolean;
}): void {
  const toast = createStandaloneToast();
  // const customToast = createStandaloneToast({ theme: yourCustomTheme })
  toast({
    title: showTitle ? typeMapper[type] : undefined,
    description: message,
    status: statusTypeMapper[type],
    duration: 5000,
    isClosable: true,
    position: 'top',
  });
}

export default showNotification;
