import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { get } from 'lodash';
import { setAnalyticsUserProperties } from '../../lib/analytics';
import { isKidAuthSelector } from '../../store/reducers/authSlice';
import { RootState } from '../../store/reducers';

interface PublicRouteProps {
  component?: any;
  path: string;
  exact: boolean;
  allowAuth?: boolean;
}

const PublicRoute = ({
  component: Component,
  path,
  exact,
  allowAuth = false,
  ...rest
}: PublicRouteProps): React.ReactElement => {
  const isAuthenticated = useSelector((state) =>
    get(state, 'authReducer.isAuthenticated'),
  );
  const loginReason = useSelector((state) =>
    get(state, 'authReducer.loginReason'),
  );
  const authRoute = loginReason === 'invite' ? '/assign-gift-cards' : '/home';
  const isKid = useSelector((state: RootState) => isKidAuthSelector(state));
  useEffect(() => {
    if (!isAuthenticated) {
      setAnalyticsUserProperties('anonymous');
    } else {
      setAnalyticsUserProperties(isKid ? 'kid' : 'parent');
    }
  }, [isAuthenticated, isKid]);
  const shouldAccess = !isAuthenticated || allowAuth;
  // eslint-disable-next-line no-console
  console.log(
    `route.public[${path}]:${authRoute}, shouldAccess:${shouldAccess}, n:${Component.name}`,
  );
  return (
    <Route
      exact={exact}
      {...rest}
      path={path}
      render={(props) =>
        shouldAccess ? <Component {...props} /> : <Redirect to={authRoute} />
      }
    />
  );
};

export default React.memo(PublicRoute);
