import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from '@chakra-ui/react';

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import Header from '../../layout/Header/Header';
import ChildDetail from '../../components/ChildDetail';
import { RootState } from '../../store/reducers';
import {
  getParentProfile,
  selectChildren,
  selectCurrentChild,
  updateKid,
} from '../../store/reducers/homeSlice';
import Divider from '../../components/Divider/Divider';
import AvatarPicker from '../../components/AvatarPicker';
import ActivityIndicator from '../../components/ActivityIndicator';
import Container from '../../components/Container';
import FormStack from '../../components/FormStack';
import { passwordValidationNotRequired } from '../../lib/passwordValidation';
import PasswordValidationPrompts from '../Register/PasswordValidationPrompts';
import { reCaptchaKey } from '../../apis';

import styles from './styles.module.scss';
import PasswordInput from '../../components/PasswordInput';
import BodyContainer from '../../components/BodyContainer';
import WealthieHelmet from '../../components/WealthieHelmet';

const ChildEditSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .required('Required'),
  ...passwordValidationNotRequired,
});

function EditChildProfile(): React.ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();
  const [avatarId, setAvatarId] = useState<number>(1);
  const children = useSelector((state: RootState) => selectChildren(state));
  const currentChildStatus = useSelector((state: RootState) =>
    selectCurrentChild(state),
  );
  const { childId } = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const currentChild = useMemo(
    () => children.find((child) => String(child.id) === String(childId)),
    [children, childId],
  );

  useEffect(() => {
    dispatch(getParentProfile());
  }, [dispatch]);

  useEffect(() => {
    async function setInitialValue() {
      if (currentChild?.avatarId) {
        setAvatarId(currentChild.avatarId);
      }
    }
    setInitialValue();
  }, [currentChild]);

  const onSubmit = useCallback(
    async (values, actions) => {
      console.error(values);
      if (!executeRecaptcha) {
        actions.setErrors({
          firstName: 'Error with form.  Please reload and try again',
        });
        return false;
      }
      const recaptchaToken = await executeRecaptcha('editChild');

      dispatch(
        updateKid(
          {
            firstName: values.firstName,
            lastName: values.lastName,
            avatarId: values.avatarId || 1,
            password: values.password || undefined,
            token: recaptchaToken,
          },
          history,
          currentChild?.id,
          '/my-kids',
        ),
      );
    },
    [dispatch, currentChild, executeRecaptcha, history],
  );

  const isSaving = currentChildStatus.isLoading;

  return (
    <Container data-testid="edit_child_profile_page">
      <WealthieHelmet title="Edit Child Profile" />
      <Header headerStyle="backArrow" showNav />
      <Heading
        as="h1"
        fontSize="largeText"
        fontWeight="largeText"
        lineHeight="title"
        textAlign="center"
        marginBottom="20px"
      >
        Edit Kid Account
      </Heading>
      <ChildDetail
        isShowInformation={false}
        {...currentChild}
        avatarId={avatarId}
      />
      <BodyContainer>
        <Formik
          initialValues={{
            firstName: currentChild?.firstName ? currentChild.firstName : '',
            lastName: currentChild?.lastName ? currentChild.lastName : '',
            userId: currentChild?.cognitoUserId
              ? currentChild.cognitoUserId
              : '',
            avatarId: currentChild?.avatarId ? currentChild.avatarId : 1,
            // day: '',
            // month: '',
            // year: '',
            password: '',
            passwordRepeat: '',
          }}
          onSubmit={onSubmit}
          validationSchema={ChildEditSchema}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form>
              <FormStack data-testid="edit_child_profile_form">
                <Field name="avatarId">
                  {({ form }) => (
                    <FormControl
                      isInvalid={form.errors.avatarId && form.touched.avatarId}
                    >
                      <AvatarPicker
                        {...currentChild}
                        onChange={(index: number) => {
                          form.setFieldValue('avatarId', index);
                          setAvatarId(index);
                        }}
                        avatarId={avatarId}
                      />
                      <Divider
                        customPadding
                        className={styles.divider_spacing}
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="firstName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.firstName && form.touched.firstName
                      }
                    >
                      <FormLabel>First Name</FormLabel>
                      <Input
                        {...field}
                        // id="firstname-id"
                        type="text"
                        data-testid="firstname-id"
                        autoComplete="given-name"
                        placeholder="First Name"
                      />
                      <FormErrorMessage>
                        {form.errors.firstName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.lastName && form.touched.lastName}
                    >
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        {...field}
                        // id="lastname-id"
                        data-testid="lastname-id"
                        type="text"
                        autoComplete="family-name"
                        placeholder="Last Name"
                      />
                      <FormErrorMessage>
                        {form.errors.lastName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="userId">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.userId && form.touched.userId}
                    >
                      <FormLabel>Username</FormLabel>
                      <Input
                        {...field}
                        // id="userId-id"
                        data-testid="user-id"
                        type="username"
                        placeholder="Username"
                        disabled
                        required
                      />
                      <FormErrorMessage>{form.errors.userId}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel>Password</FormLabel>
                      <PasswordInput
                        {...field}
                        autoComplete="new-password"
                        placeholder="New Password"
                        data-testid="password-id"
                      />
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                      <PasswordValidationPrompts passwordValue={field.value} />
                    </FormControl>
                  )}
                </Field>
                <Field name="passwordRepeat">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.passwordRepeat &&
                        form.touched.passwordRepeat
                      }
                    >
                      <FormLabel>Confirm Password</FormLabel>
                      <PasswordInput
                        {...field}
                        //id="passwordRepeat-id"
                        data-testid="passwordRepeat-id"
                        placeholder="Confirm Password"
                        autoComplete="new-password"
                      />
                      <FormErrorMessage>
                        {form.errors.passwordRepeat}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Center>
                  <Button
                    isLoading={formik.isSubmitting}
                    disabled={!formik.isValid}
                    type="submit"
                    variant="action"
                    colorScheme="accent"
                    data-testid="submit-button"
                  >
                    SAVE CHANGES
                  </Button>
                </Center>
              </FormStack>
            </Form>
          )}
        </Formik>
      </BodyContainer>
      {(isSaving || !currentChild) && <ActivityIndicator tip="Working" />}
    </Container>
  );
}

const EditChildProfileWrapper = (props: any): React.ReactElement => (
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
    <EditChildProfile {...props} />
  </GoogleReCaptchaProvider>
);

export default EditChildProfileWrapper;
