import React from 'react';
import { Box, Container, ContainerProps } from '@chakra-ui/react';

const ContainerComponent: React.FC<ContainerProps> = (
  props: ContainerProps,
) => (
  <Container maxW={['lg']} paddingX="0" bg="white" minH={'100vh'} {...props}>
    {/* The color white is only temporary and is here to show off the gutter better */}
    <Box w="100%">{props.children}</Box>
  </Container>
);

export default ContainerComponent;
