import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text,
  ModalBody,
} from '@chakra-ui/react';

import { Formik, Form, Field } from 'formik';

import Header from '../../layout/Header/Header';
import Divider from '../../components/Divider/Divider';
import Container from '../../components/Container';
import { AchievementBadgeImage } from '../../components/AchievementBadge';

import { useDispatch, useSelector } from 'react-redux';
import { loginWealthie, selectIsLoading } from '../../store/reducers/authSlice';
import SignUp from '../../components/SignUp/SignUp';
import { RootState } from '../../store/reducers';
import ActivityIndicator from '../../components/ActivityIndicator';
import FormStack from '../../components/FormStack';
import PasswordInput from '../../components/PasswordInput';
import CustomModal from '../../components/CustomModal';
import BodyContainer from '../../components/BodyContainer';
import WealthieHelmet from '../../components/WealthieHelmet';

function LoginPage(): React.ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state: RootState) => selectIsLoading(state));
  const onSubmit = useCallback(
    async (values) => {
      dispatch(loginWealthie(values.email, values.password, history));
    },
    [history, dispatch],
  );

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    email: Yup.string().required('Email is required'),
  });

  return (
    <Container data-testid="login-page">
      <WealthieHelmet title="Login" />
      <Header />
      <Center>
        <AchievementBadgeImage image="welcome" mb="30px" />
      </Center>
      <BodyContainer>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={onSubmit}
          validationSchema={LoginSchema}
        >
          {(formik) => (
            <Form>
              <FormStack data-testid="login-form">
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel htmlFor="email">
                        Email Address or Username
                      </FormLabel>
                      <Input
                        {...field}
                        type="text"
                        autoCapitalize="none"
                        autoCorrect="none"
                        id="email-id"
                        data-testid="email-id"
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel htmlFor="email">Password</FormLabel>
                      <PasswordInput
                        {...field}
                        id="password-id"
                        data-testid="password-id"
                        autoComplete="new-password"
                        // placeholder="Password"
                      />
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Center>
                  <Button
                    isLoading={formik.isSubmitting}
                    disabled={!formik.isValid}
                    type="submit"
                    data-testid="submit-button"
                    variant="infoAction"
                    colorScheme="accent"
                    marginTop="30px"
                  >
                    SIGN IN
                  </Button>
                </Center>
              </FormStack>
            </Form>
          )}
        </Formik>
        <Center marginTop="20px">
          <Box>
            <Text
              fontWeight="smallText"
              fontSize="smallTitle"
              lineHeight="largeText"
              as={Link}
              to="/forgot-pwd"
              textDecorationThickness="1px"
              textDecorationColor="brand.primary"
            >
              Forgot password?
            </Text>
          </Box>
        </Center>
        <Divider />
        <Box marginBottom="50px">
          <SignUp />
        </Box>
        {isLoading && <ActivityIndicator tip="Working" />}
        {/* TODO: Link Custom Modal instead of previous error messsage */}
        <CustomModal
          isOpen={false}
          onClose={() => {
            // console.log('placeholder');
          }}
          modalType="error"
          hasClose
        >
          <ModalBody
            p="0px"
            mb="30px"
            textAlign="center"
            fontWeight="smallText"
            fontSize="smallTitle"
            lineHeight="medText"
          >
            Oops! The email address or password is incorrect. Please double
            check your input!
          </ModalBody>
        </CustomModal>
      </BodyContainer>
    </Container>
  );
}

export default LoginPage;
