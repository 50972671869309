import React from 'react';

import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  AccordionItem,
  AccordionPanel,
  Link,
  Box,
  Text,
} from '@chakra-ui/react';
import { Field, getIn } from 'formik';

import FormDOBInput from '../../components/FormDOBInput';
import PasswordInput from '../../components/PasswordInput';
import { FormAccordionButton } from '../../components/FormAccordion/AccordionButton';
import FormStack from '../../components/FormStack';
import { ChildSignupSchemaOptional } from '../ChildSignUp';
import PasswordValidationPrompts from './PasswordValidationPrompts';

interface Props {
  childIndex: number;
  kid: any;
}

export function ChildSignupAccordionItem({
  childIndex,
  kid,
}: Props): React.ReactElement<Props> {
  let kidValid = false;
  //TODO: check if kid values are just null. and check for errors for this kid (would have to be passed in)
  try {
    ChildSignupSchemaOptional.validateSync(kid);
    kidValid = true;
  } catch (e) {
    // console.log(`kid with id: ${childIndex} is not valid`, kid);
  }
  let borderColor = 'rgba(237, 237, 237, 1)';
  let closedBorderColor = borderColor;
  if (kidValid) {
    borderColor = 'rgba(97, 220, 243, 1)';
    closedBorderColor = borderColor;
  } else {
    closedBorderColor = 'red';
  }
  return (
    <AccordionItem paddingX="0" paddingY="0" border={`0`} borderRadius="0">
      {({ isExpanded }) => (
        <Box
          paddingX="20px"
          paddingY="30px"
          border={`2px solid ${isExpanded ? borderColor : closedBorderColor}`}
          borderRadius="20px"
        >
          <FormAccordionButton
            title={
              childIndex === 0
                ? 'The Lucky Kid'
                : `Lucky Kid #${childIndex + 1}`
            }
            isExpanded={isExpanded}
          />
          <AccordionPanel padding="0px" marginTop="20px">
            <FormStack data-testid={`child[${childIndex}]_register_form`}>
              <Field name={`kids[${childIndex}].firstName`}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                  >
                    <FormLabel htmlFor={field.name}>First Name</FormLabel>
                    <Input
                      {...field}
                      value={field.value || ''}
                      id={`kids[${childIndex}].firstname-id`}
                      data-testid={`kids[${childIndex}].firstname-id`}
                      type="text"
                      autoComplete="given-name" // placeholder="First Name"
                    />
                    <FormErrorMessage>
                      {getIn(form.errors, field.name)}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={`kids[${childIndex}].lastName`}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                  >
                    <FormLabel htmlFor={field.name}>Last Name</FormLabel>
                    <Input
                      {...field}
                      value={field.value || ''}
                      id={`kids[${childIndex}].lastname-id`}
                      data-testid={`kids[${childIndex}].lastname-id`}
                      type="text"
                      autoComplete="family-name" // placeholder="Last Name"
                    />
                    <FormErrorMessage>
                      {getIn(form.errors, field.name)}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={`kids[${childIndex}].username`}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                  >
                    <FormLabel htmlFor={field.name}>Username</FormLabel>
                    <Input
                      {...field}
                      value={field.value || ''}
                      id={`kids[${childIndex}].username-id`}
                      data-testid={`kids[${childIndex}].username-id`}
                      type="text"
                      autoComplete="do-not-autofill" // placeholder="Username"
                    />
                    {!form.isValidating &&
                      getIn(getIn(form.errors, field.name), 'suggestion') && (
                        <>
                          <FormErrorMessage>
                            {getIn(getIn(form.errors, field.name), 'message')}
                          </FormErrorMessage>
                          <Text
                            fontSize="sm"
                            fontWeight="normal"
                            textAlign="left"
                          >
                            use{' '}
                            <Link
                              onClick={() => {
                                form.setFieldValue(
                                  field.name,
                                  getIn(
                                    getIn(form.errors, field.name),
                                    'suggestion',
                                  ),
                                );
                              }}
                            >
                              {getIn(
                                getIn(form.errors, field.name),
                                'suggestion',
                              )}
                            </Link>{' '}
                            instead
                          </Text>
                        </>
                      )}
                    {getIn(form.errors, field.name) &&
                      !getIn(getIn(form.errors, field.name), 'suggestion') && (
                        <FormErrorMessage>
                          {getIn(form.errors, field.name)}
                        </FormErrorMessage>
                      )}
                  </FormControl>
                )}
              </Field>
              <FormDOBInput namePrefix={`kids[${childIndex}].`} />
              <Field name={`kids[${childIndex}].password`} marginTop="0">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                    marginTop="0 !important"
                  >
                    <FormLabel htmlFor={field.name}>Password</FormLabel>
                    <PasswordInput
                      {...field}
                      value={field.value || ''}
                      id={`kids.${childIndex}.password-id`}
                      data-testid={`kids.${childIndex}.password-id`}
                      autoComplete="off" // placeholder="Password"
                    />
                    <FormErrorMessage>
                      {getIn(form.errors, field.name)}
                    </FormErrorMessage>
                    <PasswordValidationPrompts passwordValue={field.value} />
                  </FormControl>
                )}
              </Field>
              <Field name={`kids[${childIndex}].passwordRepeat`}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                  >
                    <FormLabel htmlFor={field.name}>Confirm Password</FormLabel>
                    <PasswordInput
                      {...field}
                      value={field.value || ''}
                      id={`kids.${childIndex}.passwordRepeat-id`}
                      data-testid={`kids.${childIndex}.passwordRepeat-id`}
                      autoComplete="off" // placeholder="Repeat Password"
                    />
                    <FormErrorMessage>
                      {getIn(form.errors, field.name)}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </FormStack>
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
}
