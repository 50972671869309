import { theme as baseTheme } from '@chakra-ui/react';

const brand19 = '1.1875rem';

const typography = {
  fonts: {
    body: 'ProximaNova',
    heading: 'ProximaNova',
    menu: 'ProximaNova, serif',
    title: 'Intro-Inline, monospace',
    subTitle: 'ProximaNova, serif',
    subTitleSection: 'ProximaNova, serif',
    badge: 'ProximaNova Alt Bold, serif',
  },

  fontSizes: {
    ...baseTheme.fontSizes,
    title: '1.5rem', // 24px
    subTitle: '1.625rem', // 26px
    subTitleSection: '1.3125rem', // 21px
    menu: '20px',
    largeText: '1.875rem', // 30px
    smallTitle: '1.3125rem', // 21px
    subText: '1.125rem', // 18px
    extraSmallText: '0.875rem', // 14px
    badge: brand19,
  },

  fontWeights: {
    ...baseTheme.fontWeights,
    menu: '900',
    smallText: '400',
    medText: '600',
    largeText: '700',
  },
  lineHeights: {
    extraSmallText: '1.313rem', // 21px
    smallText: '1.5rem', // 24px
    medText: '1.625rem', // 26px
    largeText: '1.9375rem', // 31px
    title: '2.25rem', // 36px
  },
};
export type Typography = typeof typography;

export default typography;
