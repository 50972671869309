import React from 'react';
import { Stack, StackProps } from '@chakra-ui/react';
import FormContainer from '../FormContainer';

const FormStack: React.FC<StackProps> = (props) => (
  <FormContainer>
    <Stack spacing={'20px'} {...props} alignItems="center">
      {props.children}
    </Stack>
  </FormContainer>
);

export default FormStack;
