import { ComponentStyleConfig } from '@chakra-ui/react';

const FormLabel: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {
    md: {
      mx: 0,
      marginBottom: '10px',
      fontSize: '1.3125rem',
      fontWeight: '600',
      lineHeight: '32px',
    },
  },
  variants: {},
  defaultProps: {
    size: 'md',
  },
};

export default FormLabel;
