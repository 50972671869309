import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Link, Image, HStack, SimpleGrid } from '@chakra-ui/react';

import { RootState } from '../../store/reducers';
import { selectParent } from '../../store/reducers/homeSlice';
import { logoutWealthie } from '../../store/reducers/authSlice';
import styles from './styles.module.scss';
import FlyingRobot from '../../assets/images/flatAssets/flying-robot.png';
import Facebook from '../../assets/images/socialIcons/facebook-logo.svg';
import Linkedin from '../../assets/images/socialIcons/linkedin-logo.svg';
import Twitter from '../../assets/images/socialIcons/twitter-logo.svg';
import Instagram from '../../assets/images/socialIcons/instagram-logo.svg';
import MenuMosaic from '../../assets/images/mosaics/menu-mosaic.svg';
import NotificationButton from '../../components/NotificationButton';

interface HeaderLandingProps {
  className?: string;
  showNav?: boolean;
  showLogoutOnly?: boolean;
  openMenu?: boolean;
  closeMenu?: (number) => void;
}

function HighLightedLink(props) {
  return (
    <Link
      as={props.isExternal ? null : NavLink}
      id={props.id}
      to={props.isExternal ? null : props.href}
      href={props.href}
      onClick={props.onClick}
      variant="menu"
      w="100%"
      px="0"
      py="0"
      isExternal={props.isExternal}
      // activeClassName="activeLink"
      _hover={{
        color: 'brand.titleHighlight',
        fontWeight: 600,
      }}
      _active={{
        color: 'brand.titleHighlight',
        fontWeight: 600,
      }}
    >
      {props.children}
    </Link>
  );
}

function HeaderMenu({
  openMenu,
  showLogoutOnly,
  closeMenu,
}: HeaderLandingProps): React.ReactElement {
  const dispatch = useDispatch();

  const { id: parentId } = useSelector((state: RootState) =>
    selectParent(state),
  );

  const logout = useCallback(() => {
    dispatch(logoutWealthie());
  }, [dispatch]);

  const MenuLink = useCallback(
    (props) => <HighLightedLink onClick={closeMenu} {...props} />,
    [closeMenu],
  );

  return (
    <Box position="relative">
      <Box
        position="absolute"
        bg="brand.headerColor"
        className={styles.menu}
        w="100%"
        fontFamily="menu"
        fontSize="menu"
        zIndex="99999999"
        style={{ display: openMenu ? 'block' : 'none' }}
        paddingX="15px"
        boxShadow={[
          '0px 4px 6px var(--chakra-colors-brand-shadow)',
          'inset 0 -1px var(--chakra-colors-brand-headerLine)',
        ]}
        backdropFilter="blur(10px)"
        marginTop={['-20px', '-30px']}
      >
        {!showLogoutOnly && (
          <Box position="relative" marginLeft={['0px', '5%']}>
            {/* <VStack alignItems="flex-start" spacing={5}> */}
            {/* The link hrefs will need to be changed */}
            <SimpleGrid
              gap={5}
              columns={[1, 3]}
              paddingTop={['10px', '60px']}
              // marginRight={['0px', '475px']}
              maxWidth={'725px'}
            >
              <MenuLink id="home-nav" href="/home">
                Home
              </MenuLink>
              <MenuLink id="kids-and-cards-nav" href="/my-kids">
                Kids + Cards
              </MenuLink>
              <MenuLink id="my-kids-nav" href="/gift-cards">
                Gift Card Archive
              </MenuLink>
              <MenuLink
                isExternal
                href="https://join.cidirectinvesting.com/ci-di-wealthie/"
                variant="menu"
              >
                Investments
              </MenuLink>
              <MenuLink
                id="parent-profile-nav"
                href={`/edit-profile/${parentId}`}
              >
                Edit Profile
              </MenuLink>
              <MenuLink
                isExternal
                href="https://wealthie.works/about-us/"
                variant="menu"
              >
                About Us
              </MenuLink>
              <MenuLink
                href="#"
                cursor="pointer"
                onClick={logout}
                variant="menu"
              >
                Log Out
              </MenuLink>
            </SimpleGrid>
            {/* </VStack> */}
            <Image
              src={FlyingRobot}
              position="absolute"
              // right={['35px', '129px']}
              // top={['180px', '21px']}
              right={['35px', '25px']}
              top={['180px', '180px']}
              width={['129px', '123px']}
              height={['181px', '173px']}
              transform={['none', 'scaleX(-1);']}
              alt="Wealthie Robot Mascot"
            />

            <Link
              id={'give-nav'}
              href={'https://shopwealthistg.wpengine.com/'}
              w="100%"
              px="0"
              py="0"
              style={{ textDecoration: 'none' }}
              isExternal
            >
              <NotificationButton
                buttonProps={{
                  variant: 'actionSmall',
                  colorScheme: 'accent',
                  marginTop: '30px',
                  textDecoration: 'none',
                }}
              >
                GIVE A GIFT CARD
              </NotificationButton>
            </Link>
            <HStack marginTop="50px" marginBottom="-30px" spacing="15px">
              <Link
                id={'facebook-nav'}
                href={'https://www.facebook.com/WealthieWorksDaily'}
                isExternal
              >
                <Image src={Facebook} alt="Facebook Logo" />
              </Link>
              <Link
                id={'instagram-nav'}
                href={'https://www.instagram.com/wealthie.works/'}
                isExternal
              >
                <Image src={Instagram} alt="Instagram Logo" />
              </Link>
              <Link
                id={'twitter-nav'}
                href={'https://twitter.com/WealthieWorks'}
                isExternal
              >
                <Image src={Twitter} alt="Twitter Logo" />
              </Link>
              <Link
                id={'linkedin-nav'}
                href={
                  'https://www.linkedin.com/company/wealthie-works-daily-inc'
                }
                isExternal
              >
                <Image src={Linkedin} alt="Linkedin Logo" />
              </Link>
            </HStack>
            <Box
              marginTop={['20px', '0px']}
              width="100%"
              display="flex"
              justifyContent="end"
            >
              <Image
                src={MenuMosaic}
                marginRight={['0px', '20px']}
                alt="Menu Mosaic"
              />
            </Box>
          </Box>
        )}
        {showLogoutOnly && (
          <>
            <Box className={styles.line} />
            <MenuLink href="#" cursor="pointer" onClick={logout} variant="menu">
              Log Out
            </MenuLink>
          </>
        )}
      </Box>
    </Box>
  );
}

export default HeaderMenu;
