import {
  CircularProgress,
  CircularProgressProps,
  Container,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
const Indicator = ({
  ...props
}: CircularProgressProps & { tip: string }): React.ReactElement => (
  <Container centerContent>
    <VStack
      alignContent="center"
      h="100vh"
      w="100%"
      position="fixed"
      top="0"
      bottom="0"
      bg="brand.greyBackgroundTransparent"
      zIndex="100000000000000"
      justifyContent="center"
    >
      <CircularProgress
        isIndeterminate
        color="brand.primary"
        size="64px"
        p="5px"
        bg="white"
        borderRadius="10px"
        {...props}
        data-testid="activity_indicator"
      />
    </VStack>
  </Container>
);

export default Indicator;
