import React from 'react';
import { Container } from '@chakra-ui/react';

const ContainerComponent: React.FC = ({ children }: any) => (
  <Container px="0px" maxWidth={['100%', '800px']}>
    {children}
  </Container>
);

export default ContainerComponent;
