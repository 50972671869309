const ComponentStyle = {
  baseStyle: {
    container: {
      fontWeight: 'bold',
      fontSize: '20px',
      textTransform: 'none',
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
      // height: '5rem',
      py: 3,
      textAlign: 'center',
      my: ['20px', '30px'],
      item: {
        bg: 'purple',
      },
    },
    colorScheme: 'blue',
  },
  defaultProps: {},
};

export default ComponentStyle;
