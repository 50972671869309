import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get, isArray } from 'lodash';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Box,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import Header from '../../layout/Header/Header';
import {
  Messages,
  showNotification,
  ERROR_TYPE,
} from '../../components/Notification';
import {
  childSignupApi,
  reCaptchaKey,
  validateChildUsernameApi,
} from '../../apis';
import { getParentProfile } from '../../store/reducers/homeSlice';

import Container from '../../components/Container';
import FormStack from '../../components/FormStack';
import SectionContainer from '../../components/SectionContainer';
import ActivityIndicator from '../../components/ActivityIndicator';
import AvatarPicker from '../../components/AvatarPicker';
import FormDOBInput from '../../components/FormDOBInput';
import { dobFieldsValidation, dobFromFields } from '../../lib/dobValidation';
import { passwordValidationRequired } from '../../lib/passwordValidation';
import ChildDetail from '../../components/ChildDetail';
import Divider from '../../components/Divider/Divider';
import PasswordInput from '../../components/PasswordInput';
import BodyContainer from '../../components/BodyContainer';
import { trackChildSignup } from '../../lib/analytics';
import WealthieHelmet from '../../components/WealthieHelmet';
import PasswordValidationPrompts from '../Register/PasswordValidationPrompts';

export const ChildSignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .required('Required'),
  username: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .min(6, 'Minimum length is 6 characters')
    .required('Required'),
  ...passwordValidationRequired,
  ...dobFieldsValidation,
});

export const ChildSignupSchemaOptional = Yup.object({
  firstName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .required('Required'),
  username: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .min(6, 'Minimum length is 6 characters')
    .required('Required'),
  ...passwordValidationRequired,
  ...dobFieldsValidation,
})
  .transform((value) => {
    // console.log('ChildSignupSchemaSimple transform value', value);
    // If any child property has a value, skip the transform
    if (
      value &&
      Object.values(value).some(
        (v) => !(v === null || v === undefined || v === ''),
      )
    ) {
      // console.log(
      //   'ChildSignupSchemaSimple has some value that is defined. return value object',
      // );
      return value;
    }
    // console.log(
    //   'ChildSignupSchemaSimple has no set values.  returning undefined',
    // );
    // Transform the value to undefined
    return undefined;
  })
  .default(undefined);

function ChildSignUp(): React.ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [avatarId, setAvatarId] = useState(1);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = useCallback(
    async (values: any, actions: any) => {
      try {
        setIsSaving(true);
        const dob = dobFromFields(values.day, values.month, values.year);
        if (!dob) {
          actions.setErrors({
            day: ' ',
            month: ' ',
            year: ' ',
            dob: 'must be a valid date',
          });
          setIsSaving(false);
          return false;
        }
        if (!executeRecaptcha) {
          actions.setErrors({
            firstName: 'Error with form.  Please reload and try again',
          });
          setIsSaving(false);
          return false;
        }
        try {
          const recaptchaToken = await executeRecaptcha('childSignup');
          const usernameLookupRes: any = await validateChildUsernameApi({
            username: values.username,
            token: recaptchaToken,
          });
          console.error(usernameLookupRes);
          if (usernameLookupRes && usernameLookupRes.exists) {
            actions.setErrors({
              username: {
                suggestion: usernameLookupRes.suggestion,
                message: 'Username already exists',
              },
            });
            setIsSaving(false);
            return false;
          }
        } catch (e) {
          const error = 'Error checking username.  Please try again.';
          actions.setErrors({
            username: error,
          });
          setIsSaving(false);
          return false;
        }
        const recaptchaToken = await executeRecaptcha('childSignup');
        const response: any = await childSignupApi({
          ...values,
          dob,
          token: recaptchaToken,
        });
        setIsSaving(false);
        dispatch(getParentProfile());
        if (response) {
          showNotification({
            type: 'success',
            message: Messages.registeredSuccessfully,
          });
          // response.parentGiftCards then go to assign gift cards with most recent gift card id
          if (
            response.data?.parentGiftCards &&
            isArray(response.data?.parentGiftCards) &&
            response.data?.parentGiftCards.length > 0
          ) {
            trackChildSignup(true, response.data.parentGiftCards[0].id);
            history.push({
              pathname: '/assign-gift-cards',
              state: {
                giftId: response.data?.parentGiftCards[0].id,
                gift: response.data?.parentGiftCards[0],
              },
            });
          } else {
            trackChildSignup(false);
            history.push('/');
          }
        } else {
          showNotification({
            type: ERROR_TYPE,
            message: get(
              response,
              'data.body.message',
              Messages.somethingWentWrong,
            ),
          });
        }
      } catch (error) {
        console.error(error);
        setIsSaving(false);
        const message = get(
          error,
          'response.data.error.message',
          Messages.somethingWentWrong,
        );
        showNotification({
          type: ERROR_TYPE,
          message,
        });
      }
    },
    [history, dispatch, executeRecaptcha],
  );

  return (
    <Container data-testid="child_signup_page">
      <WealthieHelmet title="Child Sign up" />
      <Header headerStyle="backArrow" showNav />
      <SectionContainer>
        {/* <FormHeading title="My Kid" /> */}
        <Heading
          as="h1"
          fontSize="largeText"
          fontWeight="largeText"
          lineHeight="title"
          textAlign="center"
          marginBottom="20px"
        >
          Create Kid Account
        </Heading>
      </SectionContainer>
      <BodyContainer>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            passwordRepeat: '',
            dob: '',
            day: '',
            month: '',
            year: '',
            avatarId: 1,
            token: '',
          }}
          onSubmit={onSubmit}
          validationSchema={ChildSignupSchema}
        >
          {(formik) => (
            <Form>
              <FormStack data-testid="child_signup_form">
                <Field name="avatarId">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.avatarId && form.touched.avatarId}
                    >
                      <ChildDetail
                        isLarge
                        isShowInformation={false}
                        avatarId={avatarId}
                      />
                      <AvatarPicker
                        onChange={(index: number) => {
                          form.setFieldValue('avatarId', index);
                          setAvatarId(index);
                        }}
                        avatarId={field.value}
                      />
                      <Divider customPadding />
                    </FormControl>
                  )}
                </Field>
                <Field name="firstName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.firstName && form.touched.firstName
                      }
                    >
                      <FormLabel>First Name</FormLabel>
                      <Input
                        {...field}
                        // id="firstname-id"
                        data-testid="firstname-id"
                        type="text"
                        autoComplete="given-name"
                        // placeholder="First Name"
                      />
                      <FormErrorMessage>
                        {form.errors.firstName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.lastName && form.touched.lastName}
                    >
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        {...field}
                        data-testid="lastname-id"
                        type="text"
                        autoComplete="family-name"
                        // placeholder="Last Name"
                      />
                      <FormErrorMessage>
                        {form.errors.firstName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="username">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.username && form.touched.username}
                    >
                      <FormLabel>Username</FormLabel>
                      <Input
                        {...field}
                        data-testid="user-id"
                        type="text"
                        autoComplete="do-not-autofill"
                        // placeholder="Username"
                      />
                      {!form.isValidating &&
                        form.errors.username &&
                        form.errors.username.suggestion && (
                          <>
                            <FormErrorMessage>
                              {form.errors.username.message}
                            </FormErrorMessage>
                            use{' '}
                            <Link
                              onClick={() => {
                                form.setFieldValue(
                                  'username',
                                  form.errors.username.suggestion,
                                );
                              }}
                            >
                              {form.errors.username.suggestion}
                            </Link>{' '}
                            instead
                          </>
                        )}
                      {form.errors.username &&
                        !form.errors.username.suggestion && (
                          <FormErrorMessage>
                            {form.errors.username}
                          </FormErrorMessage>
                        )}
                    </FormControl>
                  )}
                </Field>
                <Box>
                  <FormDOBInput />
                </Box>
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel>Password</FormLabel>
                      <PasswordInput
                        {...field}
                        data-testid="password-id"
                        autoComplete="off"
                      />
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                      <PasswordValidationPrompts passwordValue={field.value} />
                    </FormControl>
                  )}
                </Field>
                <Field name="passwordRepeat">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.passwordRepeat &&
                        form.touched.passwordRepeat
                      }
                    >
                      <FormLabel>Confirm Password</FormLabel>
                      <PasswordInput
                        {...field}
                        data-testid="passwordRepeat-id"
                        placeholder="Confirm Password"
                        autoComplete="new-password"
                      />
                      <FormErrorMessage>
                        {form.errors.passwordRepeat}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Center>
                  <Button
                    isLoading={formik.isSubmitting}
                    disabled={!formik.isValid}
                    type="submit"
                    data-testid="submit-button"
                    variant="action"
                    colorScheme="accent"
                    marginBottom="50px"
                    marginTop="30px"
                  >
                    CREATE ACCOUNT
                  </Button>
                </Center>
              </FormStack>
            </Form>
          )}
        </Formik>
      </BodyContainer>
      {isSaving && <ActivityIndicator tip="Working" />}
    </Container>
  );
}

const ChildSignupWrapper = (props: any): React.ReactElement => (
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
    <ChildSignUp {...props} />
  </GoogleReCaptchaProvider>
);

export default ChildSignupWrapper;
