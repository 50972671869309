import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      // color: 'brand.grey200',
      borderRadius: '50px',
      border: '1px solid',
      textColor: 'brand.primary',
      // borderColor: 'brand.grey200',
      paddingX: '2px',
    },
  },
  sizes: {
    lg: {
      field: {
        paddingX: '2px',
        fontSize: '1.125rem',
        maxWidth: ['100%', '620px'],
        // borderColor: 'purple',
      },
    },
  },
  variants: {
    outline: {
      field: {
        fontSize: '1.125rem',
        borderColor: 'brand.grey200',
        paddingLeft: '10px',
        paddingRight: '10px',
      },
    },
  },
  defaultProps: {
    size: 'lg',
    focusBorderColor: 'brand.titleHighlight',
    errorBorderColor: 'brand.error',
    variant: 'outline',
  },
};

export default Input;
