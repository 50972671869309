import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

interface Props {
  title?: string;
  text?: string;
}

const FormHeading = ({ title = '', text = '' }: Props): React.ReactElement => (
  <Box mb={['30px', '50px']} textAlign={['start', 'center']}>
    <Heading
      as="h1"
      textAlign="center"
      mb={['20px', '10px']}
      lineHeight="title"
      fontStyle="normal"
      letterSpacing="0.17px"
      fontSize="title"
    >
      {title}
    </Heading>
    <Text
      dangerouslySetInnerHTML={{ __html: text }}
      fontSize="smallTitle"
      fontWeight="smallText"
      lineHeight="medText"
    />
  </Box>
);

export default FormHeading;
