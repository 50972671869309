import { ComponentStyleConfig } from '@chakra-ui/react';

const Container: ComponentStyleConfig = {
  baseStyle: {},
  variants: {},
  sizes: {},
  defaultProps: {},
};

export default Container;
