import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import * as Yup from 'yup';
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Image,
  Heading,
  Accordion,
  AccordionPanel,
  Box,
  AccordionItem,
} from '@chakra-ui/react';
import { Formik, Form, Field, FieldArray } from 'formik';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import { FormAccordionButton } from '../../components/FormAccordion/AccordionButton';
import Header from '../../layout/Header/Header';
import {
  Messages,
  showNotification,
  ERROR_TYPE,
} from '../../components/Notification';
import Container from '../../components/Container';
import {
  parentSignupApi,
  reCaptchaKey,
  validateChildUsernameApi,
} from '../../apis';
import PrivacyPolicyLink from '../../components/PrivacyPolicyLink/PrivacyPolicyLink';
import FormStack from '../../components/FormStack';
import ActivityIndicator from '../../components/ActivityIndicator';
import { loginWealthie } from '../../store/reducers/authSlice';
import { passwordValidationRequired } from '../../lib/passwordValidation';
import { dobFromFields } from '../../lib/dobValidation';
import { emailValidationRequired } from '../../lib/emailValidation';
import PasswordInput from '../../components/PasswordInput';
import WelcomeImage from '../../assets/images/badges/welcome.png';
import BodyContainer from '../../components/BodyContainer';
import { trackParentSignup } from '../../lib/analytics';
import WealthieHelmet from '../../components/WealthieHelmet';

import { ChildSignupSchemaOptional } from '../ChildSignUp';

import { ChildSignupAccordionItem } from './ChildSignupAccordionItem';
import PasswordValidationPrompts from './PasswordValidationPrompts';

const SignupParentShape = {
  firstName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .required('Required'),
  ...emailValidationRequired,
  ...passwordValidationRequired,
};
const SignupParentSchema = Yup.object().shape(SignupParentShape);

const SignupSchema = Yup.object().shape({
  ...SignupParentShape,
  kids: Yup.array().of(ChildSignupSchemaOptional),
});

const initialKid = {
  // firstName: 'firstname',
  // lastName: 'lastname',
  // username: `sdfsdf`,
  // //username: `username${Date.now()}`,
  // password: 'Testing123!',
  // passwordRepeat: 'Testing123!',
  // year: '2022',
  // month: '1',
  // day: '1',
  firstName: undefined,
  lastName: undefined,
  username: undefined,
  password: undefined,
  passwordRepeat: undefined,
  year: undefined,
  month: undefined,
  day: undefined,
};

const initialParent = {
  // email: `arhunter+multiform${Date.now()}@gmail.com`,
  // emailRepeat: `arhunter+multiform${Date.now()}@gmail.com`,
  // firstName: 'Adam',
  // lastName: 'Hunter',
  // password: 'Testing123!',
  // passwordRepeat: 'Testing123!',
  // kids: [{ ...initialKid }],
  email: ``,
  emailRepeat: ``,
  firstName: '',
  lastName: '',
  password: '',
  passwordRepeat: '',
  kids: [{ ...initialKid }],
};

function ParentFormItem() {
  return (
    <FormStack data-testid="parent_register_form">
      <Field name="firstName">
        {({ field, form }) => (
          <FormControl
            isInvalid={form.errors.firstName && form.touched.firstName}
          >
            {/* <Box>Form (debug) errors:{JSON.stringify(form.errors)}</Box> */}
            <FormLabel htmlFor="firstName">First Name</FormLabel>
            <Input
              {...field}
              id="firstname-id"
              data-testid="firstname-id"
              type="text"
              autoComplete="given-name"
            />
            <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="lastName">
        {({ field, form }) => (
          <FormControl
            isInvalid={form.errors.lastName && form.touched.lastName}
          >
            <FormLabel htmlFor="lastName">Last Name</FormLabel>
            <Input
              {...field}
              id="lastname-id"
              data-testid="lastname-id"
              type="text"
              autoComplete="family-name"
            />
            <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="email">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.email && form.touched.email}>
            <FormLabel htmlFor="email">Email Address</FormLabel>
            <Input
              {...field}
              id="email-id"
              type="email"
              autoComplete="email"
              data-testid="email-id"
            />
            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="emailRepeat">
        {({ field, form }) => (
          <FormControl
            isInvalid={form.errors.emailRepeat && form.touched.emailRepeat}
          >
            <FormLabel htmlFor="emailRepeat">Confirm Email Address</FormLabel>
            <Input
              {...field}
              id="emailRepeat-id"
              data-testid="emailRepeat-id"
              type="email"
            />
            <FormErrorMessage>{form.errors.emailRepeat}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="password">
        {({ field, form }) => (
          <FormControl
            isInvalid={form.errors.password && form.touched.password}
          >
            <FormLabel>Password</FormLabel>
            <PasswordInput
              {...field}
              id="password-id"
              autoComplete="new-password"
              data-testid="password-id"
            />
            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
            <PasswordValidationPrompts passwordValue={field.value} />
          </FormControl>
        )}
      </Field>
      <Field name="passwordRepeat">
        {({ field, form }) => (
          <FormControl
            isInvalid={
              form.errors.passwordRepeat && form.touched.passwordRepeat
            }
          >
            <FormLabel htmlFor={field.name}>Confirm Password</FormLabel>
            <PasswordInput
              {...field}
              id="passwordRepeat-id"
              data-testid="passwordRepeat-id"
              autoComplete="new-password"
            />
            <FormErrorMessage>{form.errors.passwordRepeat}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </FormStack>
  );
}

function Register(): React.ReactElement {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  //  const [childFormNumbers, setChildFormNumbers] = useState([0]);
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (values: any, actions: any) => {
    try {
      // console.debug('starting validate submit params', values);
      let hasKidError = false;
      const kidErrors: any[] = [];
      if (!executeRecaptcha) {
        console.error('recapture error');
        actions.setErrors({
          firstName:
            'Sorry, there was an error with the form.  Please reload and try again',
        });
        setIsSaving(false);
        return false;
      }
      const managedKids = values.kids.map((kid, index) => ({
        form: kid,
        index,
      }));
      const filteredKids = managedKids.filter((k) =>
        Object.values(k.form).some(
          (v) => !(v === null || v === undefined || v === ''),
        ),
      );
      // console.debug(
      //   `filtered kids are.  initial kids array is: ${managedKids.length}`,
      //   filteredKids,
      // );
      if (filteredKids) {
        filteredKids.forEach((kid) => {
          const dob = dobFromFields(
            kid.form.day,
            kid.form.month,
            kid.form.year,
          );
          if (!dob) {
            kidErrors.push({
              day: ' ',
              month: 'Must be a valid date',
              year: ' ',
            });
            hasKidError = true;
          } else {
            kidErrors.push({}); // none so far
            kid.form.dob = dob;
          }
        });
        let kidIndex = 0;
        try {
          for (
            kidIndex = 0;
            kidIndex < filteredKids.length && !hasKidError;
            kidIndex += 1
          ) {
            const recaptchaToken = await executeRecaptcha('childSignup');
            const params = {
              username: filteredKids[kidIndex].form.username,
              token: recaptchaToken,
              index: filteredKids[kidIndex].index,
            };
            // eslint-disable-next-line no-console
            console.debug('lookup params are', params);
            const usernameLookupRes: any = await validateChildUsernameApi(
              params,
            );
            // eslint-disable-next-line no-console
            console.debug(usernameLookupRes);
            if (usernameLookupRes && usernameLookupRes.exists) {
              kidErrors[filteredKids[kidIndex].index] = {
                ...kidErrors[filteredKids[kidIndex].index],
                username: {
                  suggestion: usernameLookupRes.suggestion,
                  message: 'Username already exists',
                },
              };
              hasKidError = true;
            }
          }
        } catch (e) {
          const error = 'Error checking username.  Please try again.';
          kidErrors[filteredKids[kidIndex].index].username = error;
          hasKidError = true;
        }
      }
      if (hasKidError) {
        setIsSaving(false);
        console.error('has kids error', kidErrors);
        actions.setErrors({
          kids: kidErrors,
        });
        return false;
      }

      const recaptchaToken = await executeRecaptcha('parentSignup');

      const params = {
        ...values,
        kids: filteredKids.map((k) => k.form), // overwrite the values.kids
        token: recaptchaToken,
      };
      // console.debug('params to send to api are:', params);
      setIsSaving(true);
      const response = await parentSignupApi(params);
      setIsSaving(false);
      if (response) {
        showNotification({
          type: 'success',
          message: Messages.registeredSuccessfully,
        });
        trackParentSignup(response.data.parentId);
        // http://localhost:3000/invite/53616c7465645f5f9619d9e2e7aae5b2e6a077c52c7a782544f30d2f0e534b60
        // we now automatically log the user in, instead of asking them to and forward to assign gift cards
        window.scrollTo(0, 0);
        const inviteCode = await localStorage.getItem('@invite');
        // if we created at least 1 kid and are here from an invite then do this
        if (filteredKids && filteredKids.length > 0 && inviteCode) {
          dispatch(
            loginWealthie(values.email, values.password, history, 'invite'),
          );
        } else {
          dispatch(loginWealthie(values.email, values.password, history));
        }
      } else {
        showNotification({
          type: ERROR_TYPE,
          message: Messages.somethingWentWrong,
        });
      }
    } catch (error) {
      setIsSaving(false);
      const message = get(
        error,
        'response.data.message',
        Messages.somethingWentWrong,
      );
      showNotification({
        type: ERROR_TYPE,
        message,
      });
    }
  };

  return (
    <Container data-testid="parent_register_page">
      <WealthieHelmet title="Register" />
      <Header />
      {/* <SectionContainer>
        <FormHeading
          title="Welcome to Wealthie!"
          text="Please create your account."
        />
      </SectionContainer> */}
      <Center marginBottom={['30px', '50px']} flexDir="column" gap="20px">
        <Image src={WelcomeImage} alt="Welcome Badge" width="179px" />
        <Heading
          as="h1"
          fontSize="largeText"
          fontWeight="largeText"
          lineHeight="title"
        >
          Create Family Account
        </Heading>
      </Center>
      <BodyContainer>
        <Formik
          enableReinitialize
          initialValues={initialParent}
          onSubmit={onSubmit}
          validationSchema={SignupSchema}
        >
          {(formik) => {
            let parentValid = false;
            //TODO: check if kid values are just null. and check for errors for this kid (would have to be passed in)
            let parentitem;
            try {
              parentitem = { ...formik.values };
              parentitem.kids = [];
              SignupParentSchema.validateSync(parentitem);
              parentValid = true;
            } catch (e) {
              // eslint-disable-next-line no-console
              // console.log(`parent is not valid`, parentitem);
            }
            let borderColor = 'rgba(237, 237, 237, 1)';
            let closedBorderColor = borderColor;
            if (parentValid) {
              borderColor = 'rgba(97, 220, 243, 1)';
              closedBorderColor = borderColor;
            } else {
              closedBorderColor = 'red';
            }
            return (
              <Form>
                <Accordion allowToggle defaultIndex={[0]}>
                  <AccordionItem
                    paddingX="0"
                    paddingY="0"
                    border={`0`}
                    borderRadius="0"
                  >
                    {({ isExpanded }) => (
                      <Box
                        paddingX="20px"
                        paddingY="30px"
                        border={`2px solid ${
                          isExpanded ? borderColor : closedBorderColor
                        }`}
                        borderRadius="20px"
                      >
                        <FormAccordionButton
                          title="Parent/Guardian"
                          isExpanded={isExpanded}
                        />
                        <AccordionPanel padding="0px" marginTop="20px">
                          <ParentFormItem />
                        </AccordionPanel>
                      </Box>
                    )}
                  </AccordionItem>
                  <FieldArray
                    name="kids"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.kids && formik.values.kids.length > 0
                          ? formik.values.kids.map((kid, idx) => (
                              <ChildSignupAccordionItem
                                key={idx}
                                childIndex={idx}
                                kid={kid}
                              />
                            ))
                          : null}
                        <Box
                          width="100%"
                          display="flex"
                          flexDir="column"
                          alignItems="center"
                          marginTop="30px"
                        >
                          <Button
                            // isLoading={formik.isSubmitting}
                            disabled={formik.isSubmitting}
                            type="button"
                            variant="actionSmall"
                            data-testid="submit-button"
                            backgroundColor="white"
                            border="solid"
                            borderWidth="2px"
                            margin="-2px"
                            borderColor="rgba(0, 50, 78, 1)"
                            textColor="rgba(0, 50, 78, 1)"
                            onClick={() => {
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              arrayHelpers.push(initialKid);
                            }}
                          >
                            ADD ANOTHER KID
                          </Button>
                        </Box>
                      </>
                    )}
                  />
                </Accordion>
                <Box
                  width="100%"
                  display="flex"
                  flexDir="column"
                  alignItems="center"
                  marginTop="30px"
                >
                  <Button
                    isLoading={formik.isSubmitting}
                    // disabled={!formik.isValid}
                    type="submit"
                    variant="actionSmall"
                    colorScheme="accent"
                    data-testid="submit-button"
                    justifyContent="center"
                  >
                    CREATE ACCOUNT
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
        <Center marginY="50px" data-testid="privacy-policy-link">
          <PrivacyPolicyLink />
        </Center>
      </BodyContainer>
      {isSaving && <ActivityIndicator tip="Working" />}
    </Container>
  );
}

const RegisterWrapper = (props: any): React.ReactElement => (
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
    <Register {...props} />
  </GoogleReCaptchaProvider>
);

export default RegisterWrapper;
