import { Box } from '@chakra-ui/react';
import React from 'react';

interface DividerProps {
  customPadding?: boolean;
  className?: string;
  longer?: boolean;
}

const Divider = ({
  customPadding = false,
  className = '',
  longer,
}: DividerProps): React.ReactElement => {
  if (customPadding) {
    return (
      <Box px="0px" m="0px" mb="0px" className={className}>
        <Box
          backgroundColor="brand.titleHighlight"
          maxWidth={['345px', longer ? '820px' : '620px']}
          height="0.5px"
          marginTop="30px"
          marginBottom="10px"
        />
      </Box>
    );
  }
  return (
    <Box px="0px" m="0px" mb="0px" className={className}>
      <Box
        backgroundColor="brand.titleHighlight"
        height="0.5px"
        my="30px"
        maxWidth={['345px', longer ? '820px' : '620px']}
        alignContent="center"
        textAlign="center"
        mx="auto"
      />
    </Box>
  );
};

export default Divider;
