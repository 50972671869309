import React from 'react';
import Plx from 'react-plx';

// Age 0
import bubbleImage from '../../assets/images/timeline/bit_bubbles@3x.png';
import smallCloud from '../../assets/images/timeline/cloud@3x.svg';
import fox from '../../assets/images/timeline/sm_fox.gif';
// Age 1
import fishes from '../../assets/images/timeline/fishes.svg';
import jellyfish from '../../assets/images/timeline/sm_jelly_fish.gif';
import age1Bubbles from '../../assets/images/timeline/age_1_bubbles.svg';
// Age 2
import rightLeaf from '../../assets/images/timeline/right-leaf.svg';
import leftLeaf from '../../assets/images/timeline/left-leaf.svg';
import flipflops from '../../assets/images/timeline/flipflops@3x.png';
import sunglasses from '../../assets/images/timeline/sunglasses.png';
import flower from '../../assets/images/timeline/flower.svg';
import ball from '../../assets/images/timeline/beach-ball.svg';
// Age 3
import origamiBirdGreen from '../../assets/images/timeline/origami-bird-green.png';
import origamiBirdYellow from '../../assets/images/timeline/origami-bird-yellow.png';
import origamiFlamingo from '../../assets/images/timeline/origami-flamingo.png';
import origamiElephant from '../../assets/images/timeline/origami-elephant.png';
import gliterStar from '../../assets/images/timeline/glitter-star.png';
// Age 4
import sun from '../../assets/images/timeline/sun@3x.png';
import rollerCoaster from '../../assets/images/timeline/RollerCoaster_AlongTrack_v001_2.gif';
// Age 5
import lolly1 from '../../assets/images/timeline/sm_popsicle.gif';
import age5Bubble from '../../assets/images/timeline/bubble_age_5.svg';
// Age 6
import pinkRobot from '../../assets/images/timeline/sm_pink_bot.gif';
import greenRobot from '../../assets/images/timeline/sm_green_bot.gif';
import neonBackground from '../../assets/images/timeline/neon_background.svg';
import neonBackgroundDesktop from '../../assets/images/timeline/age_6_background_desktop.png';
import neonWatch from '../../assets/images/timeline/neon_watch.svg';
import neonCassettePlayer from '../../assets/images/timeline/neon_cassette_player.svg';
import neonBoomBox from '../../assets/images/timeline/neon_boom_box.svg';
// Age 7
import astronaut from '../../assets/images/timeline/astronaut.png';
import purplePlanet from '../../assets/images/timeline/purple-planet.png';
import yellowPlanet from '../../assets/images/timeline/yellow-planet.png';
import ringPlanet from '../../assets/images/timeline/ring-planet.png';
import rocket from '../../assets/images/timeline/rocket.png';
import twinkle from '../../assets/images/timeline/twinkle.gif';
// Age 8
import neonTreat from '../../assets/images/timeline/NeonFood_Croissant.gif';
import neonMilkshake from '../../assets/images/timeline/NeonFood_Fries.gif';
import neonDonut from '../../assets/images/timeline/NeonFood_donut_v002_4.gif';
import neonCoffee from '../../assets/images/timeline/NeonFood_Coffee_v001.gif';
// Age 9
import skateShadow from '../../assets/images/timeline/skate_shadow@3x.png';
import skateboard from '../../assets/images/timeline/Skater_forAE.gif';
// Age 10
import stamp from '../../assets/images/timeline/travel_stamps.png';
import suitcase from '../../assets/images/timeline/suitcase@3x.png';
// Age 11
import skyCity from '../../assets/images/timeline/sky_city@3x.png';
// Age 12
import pinkAirBalloon from '../../assets/images/timeline/pink-air-balloon.svg';
import greenAirBalloon from '../../assets/images/timeline/green-air-balloon.svg';
import blueAirBalloon from '../../assets/images/timeline/blue-air-balloon.svg';
import airBalloonShadow from '../../assets/images/timeline/air-balloon-shadow.svg';
import age12Cloud from '../../assets/images/timeline/age-12-cloud.svg';
// Age 13
import greenCamera from '../../assets/images/timeline/green-camera.gif';
import yellowCamera from '../../assets/images/timeline/yellow-camera.gif';
import filmRoll from '../../assets/images/timeline/film-roll.png';
import filmRoll2 from '../../assets/images/timeline/film-roll-2.png';
// Age 14
import gaming from '../../assets/images/timeline/14_gaming.png';
import gamingDesktop from '../../assets/images/timeline/14_gaming_desktop.png';
import coinFlip from '../../assets/images/timeline/coin-flip.gif';
// Age 15
import tickets from '../../assets/images/timeline/tickets.png';
import headphones from '../../assets/images/timeline/headphones.png';
import recordPlayer from '../../assets/images/timeline/record-player.png';
// Age 16
import sprayPaint from '../../assets/images/timeline/spray_paint@3x.png';
import cloudDreams from '../../assets/images/timeline/cloud_dreams.png';
// Age 17
import lightening1 from '../../assets/images/timeline/lightening1.png';
import lightening2 from '../../assets/images/timeline/lightening2.png';
import battery from '../../assets/images/timeline/battery.gif';
// Age 18
import hats from '../../assets/images/timeline/graduation_hat@3x.png';
import coinFountain from '../../assets/images/timeline/coin-fountain.gif';

import cta from '../../assets/images/timeline/plus_icon.png';

import styles from './styles.module.scss';

// const plusSign = '/assets/timeline/plus@3x.png';

function buildData({ level }) {
  const data = [
    {
      start: 'self',
      duration: 2000,
      properties: [
        {
          startValue: 0,
          endValue: 0 - 100 * level * 3,
          property: 'translateY',
        },
      ],
    },
  ];
  return data;
}

function getClassName(age: number, level: number) {
  return `backgroundImageContainer_${age}_${level} backgroundImageContainer_age_${age} backgroundImageContainer_level_${level}`;
}

function backgroundImage(
  image: any,
  level: number,
  className: string,
  custom: string = '',
) {
  return (
    <img
      className={`${className} ${`backgroundImage${level}`} ${
        custom ? `${custom} ${custom}${level}` : ''
      }`}
      alt={''}
      src={image}
    />
  );
}

function ctaButton(
  level: number,
  age: number,
  index: number,
  className: string,
  onCta: (age: number, index: number, node: number) => void,
  node: number,
) {
  return (
    <img
      onClick={() => onCta(age, index, node)}
      className={`${className} plusIcon plusIcon${level} plusIcon${age}${index} ${
        index === 0 ? 'first-plus-node' : ''
      }`}
      alt={'More Information'}
      src={cta}
    />
  );
}

// function backgroundText(
//   title: string,
//   body: string,
//   level: number,
//   className: string,
// ) {
//   return (
//     <
//       className={[
//         styles.backgroundText,
//         className,
//         `backgroundText_${level}`,
//       ].join(' ')}
//     >
//       <h1>{title}</h1>
//       <p>{body}</p>
//     </>
//   );
// }

function getBackground(
  age: number,
  level: number,
  onCta: (age: number, index: number, node: number) => void,
) {
  const plusButtonCalcLevel = 0;
  let calcLevel = level;
  let backgrounds;
  let isPositionAbsolute = true;
  if (age === 0) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        bubbleImage,
        level,
        styles.backgroundImageBitBubble,
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        smallCloud,
        level,
        styles.backgroundImageSmallCloud,
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = backgroundImage(fox, level, styles.backgroundImageFox);
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      backgrounds = ctaButton(
        level,
        age,
        0,
        `${styles.plus} ${styles.plus0_1}`,
        onCta,
        1,
      );
    }
  }
  if (age === 1) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        age1Bubbles,
        level,
        styles.backgroundImageAge1Bubbles,
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        jellyfish,
        level,
        styles.backgroundImageJellyfishes,
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        fishes,
        level,
        styles.backgroundImageFishes,
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus1_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus1_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }
  if (age === 2) {
    if (level === 0) {
      calcLevel = 0;
      backgrounds = backgrounds = (
        <>
          {backgroundImage(leftLeaf, level, styles.backgroundImageLeafLeft)}
          {backgroundImage(flower, level, styles.backgroundImageFlower)}
          {backgroundImage(rightLeaf, level, styles.backgroundImageLeafRight)}
        </>
      );
    }
    if (level === 1) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(flipflops, level, styles.backgroundImageFlipFlops)}
          {backgroundImage(sunglasses, level, styles.backgroundImageSunglasses)}
          {backgroundImage(ball, level, styles.backgroundImageBall)}
        </>
      );
    }
    if (level === 2) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus2_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus2_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }
  if (age === 3) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = (
        <>
          <>
            {backgroundImage(
              origamiBirdGreen,
              level,
              styles.backgroundImageBirdGreen,
            )}
          </>
          <>
            {backgroundImage(
              origamiBirdYellow,
              level,
              styles.backgroundImageBirdYellow,
            )}
          </>
          <>
            {backgroundImage(
              origamiFlamingo,
              level,
              styles.backgroundImageFlamingo,
            )}
          </>
          <>
            {backgroundImage(
              origamiElephant,
              level,
              styles.backgroundImageElephant,
            )}
          </>
        </>
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = (
        <>
          <>{backgroundImage(gliterStar, level, styles.backgroundImageStar1)}</>
          <>{backgroundImage(gliterStar, level, styles.backgroundImageStar2)}</>
          <>{backgroundImage(gliterStar, level, styles.backgroundImageStar3)}</>
          <>{backgroundImage(gliterStar, level, styles.backgroundImageStar4)}</>
        </>
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = ctaButton(
        level,
        age,
        0,
        `${styles.plus} ${styles.plus3_1}`,
        onCta,
        1,
      );
    }
  }
  if (age === 4) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(sun, level, styles.backgroundImageSun);
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        rollerCoaster,
        level,
        styles.backgroundImageRollerCoaster,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = ctaButton(
        level,
        age,
        0,
        `${styles.plus} ${styles.plus4_1}`,
        onCta,
        1,
      );
    }
  }

  if (age === 5) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(
            age5Bubble,
            level,
            styles.backgroundImageAge5Bubble1,
          )}
          {backgroundImage(
            age5Bubble,
            level,
            styles.backgroundImageAge5Bubble2,
          )}
          {backgroundImage(
            age5Bubble,
            level,
            styles.backgroundImageAge5Bubble3,
          )}
        </>
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        lolly1,
        level,
        styles.backgroundImageLolly1,
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        lolly1,
        level,
        styles.backgroundImageLolly2,
      );
    }
    if (level === 4) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        lolly1,
        level,
        styles.backgroundImageLolly3,
      );
    }
    if (level === 5) {
      calcLevel = plusButtonCalcLevel;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus5_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus5_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }
  if (age === 6) {
    if (level === 1 && window.innerWidth <= 480) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        neonBackground,
        level,
        styles.backgroundImageNeonBackground,
      );
    }
    if (level === 1 && window.innerWidth > 480) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        neonBackgroundDesktop,
        level,
        styles.backgroundImageNeonBackground,
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(neonWatch, level, styles.backgroundImageWatch)}
          {backgroundImage(
            neonCassettePlayer,
            level,
            styles.backgroundImageCassette,
          )}
          {backgroundImage(neonBoomBox, level, styles.backgroundImageBoomBox)}
        </>
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(pinkRobot, level, styles.backgroundImagePinkRobot)}
          {backgroundImage(greenRobot, level, styles.backgroundImageGreenRobot)}
        </>
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus6_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus6_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 7) {
    if (level === 1) {
      isPositionAbsolute = true;
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(twinkle, level, styles.backgroundImageAge7Twinkle1)}
          {backgroundImage(twinkle, level, styles.backgroundImageAge7Twinkle2)}
          {backgroundImage(twinkle, level, styles.backgroundImageAge7Twinkle3)}
          {backgroundImage(twinkle, level, styles.backgroundImageAge7Twinkle4)}
        </>
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(rocket, level, styles.backgroundImageRocket)}
          {backgroundImage(
            purplePlanet,
            level,
            styles.backgroundImagePurplePlanet,
          )}
          {backgroundImage(
            yellowPlanet,
            level,
            styles.backgroundImageYellowPlanet,
          )}
          {backgroundImage(ringPlanet, level, styles.backgroundImageRingPlanet)}
        </>
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        astronaut,
        level,
        styles.backgroundImageAstronaut,
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus7_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus7_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 8) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        neonCoffee,
        level,
        styles.backgroundImageNeonCoffee,
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        neonTreat,
        level,
        styles.backgroundImageNeonCroissant,
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        neonDonut,
        level,
        styles.backgroundImageNeonDonut,
      );
    }
    if (level === 4) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        neonMilkshake,
        level,
        styles.backgroundImageNeonNoodles,
      );
    }
    if (level === 5) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus8_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus8_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }
  if (age === 9) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        skateShadow,
        level,
        styles.backgroundImageSkateShadow,
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        skateboard,
        level,
        styles.backgroundImageSkateboard,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus9_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus9_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 10) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(twinkle, level, styles.backgroundImageAge10Twinkle1)}
          {backgroundImage(twinkle, level, styles.backgroundImageAge10Twinkle2)}
          {backgroundImage(twinkle, level, styles.backgroundImageAge10Twinkle3)}
          {backgroundImage(twinkle, level, styles.backgroundImageAge10Twinkle4)}
        </>
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(stamp, level, styles.backgroundImageStamp);
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        suitcase,
        level,
        styles.backgroundImageSuitcase,
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus10_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus10_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 11) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        skyCity,
        level,
        styles.backgroundImageSkyCity,
      );
    }
    if (level === 2) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus11_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus11_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 12) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(
            airBalloonShadow,
            level,
            styles.backgroundImageAirBaloonShadow1,
          )}
          {backgroundImage(
            airBalloonShadow,
            level,
            styles.backgroundImageAirBaloonShadow2,
          )}
          {backgroundImage(
            airBalloonShadow,
            level,
            styles.backgroundImageAirBaloonShadow3,
          )}
          {backgroundImage(
            airBalloonShadow,
            level,
            styles.backgroundImageAirBaloonShadow4,
          )}
        </>
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(
            blueAirBalloon,
            level,
            styles.backgroundImageBlueAirBalloon,
          )}
          {backgroundImage(
            greenAirBalloon,
            level,
            styles.backgroundImageGreenAirBalloon,
          )}
          {backgroundImage(
            pinkAirBalloon,
            level,
            styles.backgroundImagePinkAirBalloon,
          )}
        </>
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(
            age12Cloud,
            level,
            styles.backgroundImageAge12Cloud1,
          )}
          {backgroundImage(
            age12Cloud,
            level,
            styles.backgroundImageAge12Cloud2,
          )}
          {backgroundImage(
            age12Cloud,
            level,
            styles.backgroundImageAge12Cloud3,
          )}
        </>
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = ctaButton(
        level,
        age,
        0,
        `${styles.plus} ${styles.plus12_1}`,
        onCta,
        1,
      );
    }
  }

  if (age === 13) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(twinkle, level, styles.backgroundImageAge13Twinkle1)}
          {backgroundImage(twinkle, level, styles.backgroundImageAge13Twinkle2)}
          {backgroundImage(twinkle, level, styles.backgroundImageAge13Twinkle3)}
          {backgroundImage(twinkle, level, styles.backgroundImageAge13Twinkle4)}
          {backgroundImage(twinkle, level, styles.backgroundImageAge13Twinkle5)}
        </>
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(filmRoll, level, styles.backgroundImageFilmRoll11)}
          {backgroundImage(filmRoll, level, styles.backgroundImageFilmRoll12)}
          {backgroundImage(filmRoll2, level, styles.backgroundImageFilm)}
        </>
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(
            greenCamera,
            level,
            styles.backgroundImageGreenCamera,
          )}
          {backgroundImage(
            yellowCamera,
            level,
            styles.backgroundImageYellowCamera,
          )}
        </>
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = ctaButton(
        level,
        age,
        0,
        `${styles.plus} ${styles.plus13_1}`,
        onCta,
        1,
      );
    }
  }

  if (age === 14) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        gaming,
        level,
        `${styles.backgroundImageGaming} ${styles.smallOnly}`,
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        gamingDesktop,
        level,
        `${styles.backgroundImageGaming} ${styles.bigOnly}`,
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(coinFlip, level, styles.backgroundImageCoinFlip1)}
          {backgroundImage(coinFlip, level, styles.backgroundImageCoinFlip2)}
          {backgroundImage(coinFlip, level, styles.backgroundImageCoinFlip3)}
          {backgroundImage(coinFlip, level, styles.backgroundImageCoinFlip4)}
        </>
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus14_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus14_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 15) {
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        tickets,
        level,
        styles.backgroundImageTickets,
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        headphones,
        level,
        styles.backgroundImageHeadphones,
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        recordPlayer,
        level,
        styles.backgroundImageRecordPlayer,
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus15_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus15_2}`,
            onCta,
            2,
          )}
        </>
      );
    }

    // if (level === 2) {
    //   calcLevel = 1.0;
    //   backgrounds = backgroundText(
    //     'The Title Text Section (Age 15)',
    //     'The body text that is longer than the title. The body text than the title.',
    //     level,
    //     styles.backgroundImageLighteningBolt,
    //   );
    // }
    // if (level === 1) {
    //   calcLevel = 0.1;
    //   backgrounds = backgroundImage(
    //     lighteningBolt,
    //     level,
    //     styles.backgroundImageLighteningBoltLarge,
    //   );
    // }
  }

  if (age === 16) {
    // if (level === 2) {
    //   calcLevel = 1.0;
    //   backgrounds = backgroundText(
    //     'The Title Text Section (Age 16)',
    //     'The body text that is longer than the title. The body text than the title.',
    //     level,
    //     styles.backgroundImageLighteningBolt,
    //   );
    // }
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        cloudDreams,
        level,
        styles.backgroundImageCloudDreams,
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        sprayPaint,
        level,
        styles.backgroundImageSprayPaint,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus16_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus16_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 17) {
    // if (level === 2) {
    //   calcLevel = 1.0;
    //   backgrounds = backgroundText(
    //     'The Title Text Section (Age 17)',
    //     'The body text that is longer than the title. The body text than the title.',
    //     level,
    //     styles.backgroundImageLighteningBolt,
    //   );
    // }
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        lightening1,
        level,
        styles.backgroundImageLighteningBolt1,
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        lightening2,
        level,
        styles.backgroundImageLighteningBolt2,
      );
    }
    if (level === 3) {
      calcLevel = 0;
      backgrounds = (
        <>
          {backgroundImage(battery, level, styles.backgroundImageBattery1)}
          {backgroundImage(battery, level, styles.backgroundImageBattery2)}
          {backgroundImage(battery, level, styles.backgroundImageBattery3)}
        </>
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus17_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus17_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 18) {
    // if (level === 2) {
    //   calcLevel = 1.0;
    //   backgrounds = backgroundText(
    //     'The Title Text Section (Age 18)',
    //     'The body text that is longer than the title. The body text than the title.',
    //     level,
    //     styles.backgroundImageLighteningBolt,
    //   );
    // }
    if (level === 1) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        hats,
        level,
        styles.backgroundImageGraduationCap,
      );
    }
    if (level === 2) {
      calcLevel = 0;
      backgrounds = backgroundImage(
        coinFountain,
        level,
        styles.backgroundImageCoinFountain,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus18_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus18_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (backgrounds) {
    return (
      <Plx
        className={`${
          isPositionAbsolute
            ? styles.backgroundImagePlxPlus
            : styles.backgroundImagePlx
        } ${getClassName(age, level)}`}
        parallaxData={buildData({ level: calcLevel })}
      >
        {backgrounds}
      </Plx>
    );
  }
  return null;
}

function BackgroundLayer({
  age,
  level,
  onCta,
}: {
  age: number;
  level: number;
  onCta: (age: number, index: number, node: number) => void;
}): React.ReactElement {
  return <>{getBackground(age, level, onCta)}</>;
}

export default BackgroundLayer;
