import React, { useMemo } from 'react';
import { AccordionPanel, Box, Image } from '@chakra-ui/react';
import parse from 'html-react-parser';

import ageData from './agedata';
import styles from './styles.module.scss';
import { isEmpty } from 'lodash';

interface Props {
  age: number;
}

// const imageHeightsPerAge = {
//   '0': '0',
//   '1': '0',
//   '2': '0',
//   '3': '0',
//   '4': '0',
//   '5': '0',
//   '6': '0',
//   '7': '0',
//   '8': '0',
//   '9': '0',
//   '10': '0',
//   '11': '0',
//   '12': '0',
//   '13': '0',
//   '14': '0',
//   '15': '0',
//   '16': '0',
//   '17': '0',
//   '18': '0',
// };

const images = {
  image0: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-01.svg'),
  image1: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-02.svg'),
  image2: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-03.svg'),
  image3: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-04.svg'),
  image4: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-05.svg'),
  image5: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-06.svg'),
  image6: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-07.svg'),
  image7: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-08.svg'),
  image8: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-09.svg'),
  image9: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-10.svg'),
  image10: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-11.svg'),
  image11: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-12.svg'),
  image12: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-13.svg'),
  image13: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-14.svg'),
  image14: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-15.svg'),
  image15: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-16.svg'),
  image16: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-17.svg'),
  image17: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-18.svg'),
  image18: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-19.svg'),
};

function renderImage(image, age, index) {
  const props = image.props ? image.props : {};
  return (
    <Image
      align="center"
      fit="contain"
      {...props}
      src={image.src}
      // alt={`Age ${age}`}
      key={`${image.src}_${index}`}
    />
  );
}

function renderImages(images, age) {
  return images.map((image, index) => renderImage(image, age, index));
}

function AgeBlock(props: Props): React.ReactElement {
  const { age } = props;
  const ageBlockData = useMemo(() => {
    if (!ageData[age]) {
      return <p></p>;
    }
    return parse(ageData[age].html);
  }, [age]);
  const image = useMemo(() => {
    let image = images[`image${age}`];
    if (!image) {
      image = images['image0'].default;
    } else {
      image = image.default;
    }
    return image;
  }, [age]);

  const bottomImages = useMemo(() => {
    if (!ageData[age] || isEmpty(ageData[age].images)) {
      return null;
    }
    const images = renderImages(ageData[age].images, age);
    if (!ageData[age].imagesContainer || !ageData[age].imagesContainer?.props) {
      return images;
    } else {
      return <Box {...ageData[age].imagesContainer?.props}>{images}</Box>;
    }
  }, [age]);

  const outerImages = useMemo(() => {
    if (!ageData[age] || isEmpty(ageData[age].outerImages)) {
      return null;
    }
    const images = renderImages(ageData[age].outerImages, age);
    if (
      !ageData[age].outerImagesContainer ||
      !ageData[age].outerImagesContainer?.props
    ) {
      return images;
    } else {
      return <Box {...ageData[age].outerImagesContainer?.props}>{images}</Box>;
    }
  }, [age]);

  return (
    <>
      <AccordionPanel
        bg="brand.lightBackground"
        px={0}
        py={0}
        position="relative"
        borderColor="brand.titleHighlight"
        borderLeftWidth="2px"
        borderRightWidth="2px"
        borderBottomWidth="2px"
        borderBottomRadius="8px"
      >
        <Box className={styles.ageBlockContainer} px="18.5px">
          <Image
            src={image}
            alt={`Age ${age}`}
            align="left"
            // w="60%"
            // marginLeft="-30px"
            // marginTop="-10px"
            marginBottom="20px"
          />
          {ageBlockData}
          {bottomImages}
        </Box>
        <Box
          position="relative"
          // height={imageHeightsPerAge[age]}
          overflow="hidden"
        >
          {outerImages}
        </Box>
      </AccordionPanel>
    </>
  );
}

export default AgeBlock;
