import React, { useState } from 'react';
import {
  ChakraProps,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Box,
  HStack,
  Text,
  PopoverContentProps,
} from '@chakra-ui/react';
// import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { ReactComponent as QuestionMarkIconSm } from '../../assets/images/icons/question-mark-small.svg';
import { ReactComponent as QuestionMarkIconMd } from '../../assets/images/icons/question-mark-med.svg';
import { ReactComponent as QuestionMarkIconLg } from '../../assets/images/icons/question-mark-large.svg';
import { trackTooltipClose, trackTooltipOpen } from '../../lib/analytics';

interface Props extends ChakraProps {
  anchorComponent: any;
  infoText: any;
  questionMarkSize: 'sm' | 'md' | 'lg';
  analyticsSrc: string;
  popoverContentProps?: PopoverContentProps;
}

function ItemWithInfo({
  anchorComponent,
  infoText,
  questionMarkSize,
  analyticsSrc,
  popoverContentProps,
  ...chakraProps
}: Props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    if (!isOpen) {
      trackTooltipOpen(analyticsSrc);
    } else {
      trackTooltipClose(analyticsSrc);
    }
    setIsOpen(!isOpen);
  };

  const QuestionMarkButton = () => {
    const props = {
      onClick: onClick,
      cursor: 'pointer',
      title: 'More information',
    };
    switch (questionMarkSize) {
      case 'sm': {
        return <QuestionMarkIconSm {...props} />;
      }
      case 'md': {
        return <QuestionMarkIconMd {...props} />;
      }
      case 'lg': {
        return <QuestionMarkIconLg {...props} />;
      }
      default: {
        return <QuestionMarkIconMd {...props} />;
      }
    }
  };

  const fontProps = {
    fontSize: '21px',
    fontWeight: '400',
  };

  return (
    <HStack {...chakraProps}>
      <Popover trigger="click" isOpen={isOpen} closeOnBlur={true}>
        <PopoverAnchor>{anchorComponent}</PopoverAnchor>
        <PopoverTrigger>
          <Box>{QuestionMarkButton()}</Box>
        </PopoverTrigger>
        <PopoverContent
          bgColor={'brand.grey50'}
          borderStyle="solid"
          borderColor="brand.grey50"
          borderRadius="20px"
          dropShadow="brand.popoverShadow"
          boxShadow="0px 5px 55px var(--chakra-colors-brand-popoverShadow)"
          backdropFilter="blur(6px)"
          paddingX="50px"
          paddingY="30px"
          left="17px"
          width="345px"
          {...popoverContentProps}
        >
          {/* <PopoverArrow bgColor={'brand.lightBackground'} /> */}
          <PopoverBody
            padding="0px"
            maxWidth="245px"
            lineHeight="26px"
            {...fontProps}
          >
            {infoText}
          </PopoverBody>
          <Text
            marginTop="30px"
            textAlign="center"
            textDecoration="underline"
            onClick={onClick}
            cursor="pointer"
            {...fontProps}
          >
            Close
          </Text>
        </PopoverContent>
      </Popover>
    </HStack>
  );
}

export default ItemWithInfo;
