import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, HStack, IconButton, useMediaQuery } from '@chakra-ui/react';

import BackIcon from '../../components/icons/BackIcon';
import BackIconLarge from '../../components/icons/BackIcon/BackiconLarge';
import UpIcon from '../../components/icons/UpIcon';
import { ReactComponent as MenuIcon } from '../../components/icons/HamburgerIcon/hamburger-menu.svg';
import { ReactComponent as CloseIcon } from '../../components/icons/XIcon/x-icon.svg';
import HeaderTitle from './HeaderTitle';
import HeaderMenu from '../HeaderMenu';
import { USE_MEDIA_QUEREY_VALUE } from '../../constants/variables';
import { trackMenuClose, trackMenuOpen } from '../../lib/analytics';

interface HeaderLandingProps {
  hasNoti?: boolean;
  className?: string;
  showNav?: boolean;
  showLogoutOnly?: boolean;
  isBackGoHome?: boolean;
  headerStyle?: 'none' | 'hamburger' | 'backArrow';
}

function Header(props: HeaderLandingProps): React.ReactElement {
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();
  const [isDesktopSize] = useMediaQuery(USE_MEDIA_QUEREY_VALUE);

  const toggleMenu = () => {
    if (!openMenu) {
      trackMenuOpen();
    } else {
      trackMenuClose();
    }
    setOpenMenu((openMenu) => !openMenu);
  };

  const closeMenu = () => {
    trackMenuClose();
    setOpenMenu(false);
  };

  const HeaderIcon = () => {
    switch (props.headerStyle) {
      case 'hamburger': {
        return (
          <Box marginLeft="20px !important">
            {(props.showNav || props.showLogoutOnly) && (
              <IconButton
                aria-label="Menu"
                onClick={toggleMenu}
                mt="-2px"
                isRound={false}
                borderRadius="0"
                variant=""
                width="50px"
                height="50px"
                verticalAlign="top"
                marginRight={['0', '0']}
                icon={
                  openMenu ? (
                    isDesktopSize ? (
                      <UpIcon />
                    ) : (
                      <CloseIcon />
                    )
                  ) : (
                    <MenuIcon />
                  )
                }
              />
            )}
          </Box>
        );
      }
      case 'backArrow': {
        return (
          <Box>
            {props.showNav &&
            location.pathname !== '/home' &&
            history.length > 1 ? (
              <IconButton
                aria-label="Back"
                variant=""
                onClick={() => {
                  if (location.pathname === '/my-kids' || props.isBackGoHome) {
                    history.replace('/home');
                  } else {
                    history.goBack();
                  }
                }}
                marginLeft={[0, '50px']}
                icon={isDesktopSize ? <BackIconLarge /> : <BackIcon />}
                width={['50px', '60px']}
                height={['50px', '60px']}
              />
            ) : (
              <IconButton aria-label="" variant="" icon={<></>} />
            )}
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };

  const backarrowWidth = '12';

  return (
    <Box marginX="0">
      <HStack
        justifyContent={[
          props.headerStyle === 'hamburger' ? 'space-between' : 'center',
          !props.headerStyle || props.headerStyle === 'none'
            ? 'center'
            : 'flex-end',
        ]}
        paddingLeft="30px"
        paddingRight="20px"
        w="100%"
        h={['60px', '105px']}
        bg="brand.headerColor"
        shadow={!openMenu ? 'brand.shadow' : ''}
        boxShadow={
          !openMenu ? '0px 4px 6px var(--chakra-colors-brand-shadow)' : ''
        }
        borderBottom={
          !openMenu ? '1px solid var(--chakra-colors-brand-headerLine)' : ''
        }
        backdropFilter="blur(10px)"
        marginBottom={['20px', '30px']}
      >
        {props.headerStyle === 'hamburger' && !isDesktopSize && (
          <Box w={backarrowWidth} />
        )}
        {props.headerStyle === 'backArrow' && <HeaderIcon />}
        <HeaderTitle />
        {props.headerStyle === 'hamburger' && <HeaderIcon />}
        {/* This box is to make the spacing the same when the headerStyle is backArrow */}
        {props.headerStyle === 'backArrow' && <Box w={backarrowWidth} />}
      </HStack>
      <HeaderMenu
        openMenu={openMenu}
        closeMenu={closeMenu}
        showLogoutOnly={props.showLogoutOnly}
        className={props.className}
      />
    </Box>
  );
}

export default Header;
