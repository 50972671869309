import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import Modal from 'react-modal';
import moment from 'moment';
import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Container as BaseContainer,
} from '@chakra-ui/react';

import Header from '../../layout/Header/Header';
import {
  getParentProfile,
  GiftCardType,
  selectChildren,
  selectGiftCards,
  UserType,
} from '../../store/reducers/homeSlice';
import ChildDetail from '../../components/ChildDetail';
import { RootState } from '../../store/reducers';
import useBodyClass from '../../helpers/useBodyClass';

import Age from './Age';
import styles from './styles.module.scss';
import AgeSign from './AgeSign';
import BackgroundLayer from './BackgroundLayer';
import WelcomeSlider from '../../layout/WelcomeSlider';

import childDivider from '../../assets/images/timeline/child_divider.png';
import GiftCardImage from '../../components/GiftCardImage';
import { cardImageFromName } from './images';
import GiftCardModal from '../../components/GiftCardModal';
import {
  ModalA0N1,
  ModalA1N2,
  ModalA1N1,
  ModalA2N1,
  ModalA2N2,
  ModalA3N1,
  ModalA4N1,
  ModalA5N1,
  ModalA5N2,
  ModalA6N1,
  ModalA6N2,
  ModalA7N1,
  ModalA7N2,
  ModalA8N1,
  ModalA8N2,
  ModalA9N1,
  ModalA9N2,
  ModalA10N1,
  ModalA10N2,
  ModalA11N1,
  ModalA11N2,
  ModalA12N1,
  ModalA13N1,
  ModalA14N1,
  ModalA14N2,
  ModalA15N1,
  ModalA15N2,
  ModalA16N1,
  ModalA16N2,
  ModalA17N1,
  ModalA17N2,
  ModalA18N1,
  ModalA18N2,
} from './AgeModals';
import { trackNodeModalClose, trackNodeModalOpen } from '../../lib/analytics';
import WealthieHelmet from '../../components/WealthieHelmet';
import ReactJoyride, { STATUS, Step } from 'react-joyride';
import TourTooltip from '../../components/TourTooltip';
import TourTooltipImage from '../../components/TourTooltipImage';
import {
  handleCreateJoyrideCookies,
  hasCompletedTour,
} from '../../helpers/reactJoyride/joyrideCookies';

// Tour Tooltip images
import Aliens from '../../assets/images/tourTooltips/aliens.png';
import TV from '../../assets/images/tourTooltips/tv.png';
import WelcomeBadge from '../../assets/images/badges/welcome.png';
import GiftCards from '../../assets/images/tourTooltips/gift-cards.png';
import { handleJoyrideCallbackAnalytics } from '../../helpers/reactJoyride/joyrideCallback';
import { calculateAge } from '../../helpers/calculateAge';
import ContainerComponent from '../../components/Container';

interface InformationParameters {
  age: number;
  index: number;
  node: number;
}

function giftCardsForAge(
  child: UserType,
  age: number,
  giftCards: GiftCardType[],
) {
  const targetYear = moment(child.dateOfBirth).year() + age;
  //  const cards = giftCards;
  const cards = giftCards.filter(
    (giftCard) => moment(giftCard.transactionTimestamp).year() === targetYear,
  );
  if (!cards) {
    return [];
  }
  return cards;
}

function AgeGiftCards({
  age,
  child,
  giftCards,
  onPress,
}: {
  age: number;
  child: UserType;
  giftCards: GiftCardType[];
  onPress: (card: GiftCardType) => void;
}) {
  const cards = giftCardsForAge(child, age, giftCards);
  return (
    <>
      {cards.map((giftCard) => (
        <Box
          key={giftCard.id}
          onClick={() => onPress(giftCard)}
          className={`${styles.cardContainer} hey-baby`}
          // zIndex="10"
        >
          <Box
            w={['112px', '100%', '150px']}
            minWidth="112px"
            h={['71px', 'auto']}
            minHeight="71px"
          >
            {/* <Image src={cardImageFromName(name)} /> */}
            <GiftCardImage
              giftCardImageSrc={cardImageFromName(giftCard.giftCardGameName)}
              size="custom"
              isRotated
              imageProps={{
                width: '100%',
                minWidth: '112px',
                maxWidth: '170px',
                height: '100%',
                minHeight: '71px',
                borderRadius: '13px',
                borderWidth: '2.5px',
              }}
            />
          </Box>
          {/* <GiftCardForName name={giftCard.giftCardGameName} isRotated /> */}
        </Box>
      ))}
    </>
  );
}

function giftCardsForChild(childId: number, giftCards: GiftCardType[]) {
  return giftCards.filter((giftCard) => Number(giftCard.childId) === childId);
}

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    zIndex: '999999999',
    marginRight: '-50%',
    width: '352px',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    overflow: 'auto',
    borderRadius: '0',
    padding: '0',
    outline: 'none',
    border: '0',
  },
};

const tourPageName = 'timeline';

const tourSteps: Step[] = [
  {
    content: (
      <TourTooltipImage
        text="Welcome to your timeline!"
        src={Aliens}
        alt="Aliens"
      />
    ),
    disableBeacon: true,
    target: '.greating-heading',
    styles: {
      spotlight: {
        borderRadius: 0,
      },
    },
    placement: 'top',
    offset: -20,
  },
  {
    content: (
      <TourTooltipImage
        text="You'll find videos and stories behind these nodes at every age."
        src={TV}
        alt="Television"
        width="117.18px"
      />
    ),
    target: '.first-plus-node',
    disableBeacon: true,
    styles: {
      spotlight: {
        borderRadius: '45px',
      },
    },
    placement: 'top',
  },

  {
    content: (
      <TourTooltipImage
        text="Here are your badges. Congratulations on becoming an investor!"
        src={WelcomeBadge}
        alt="Welcome Badge"
        width="131px"
      />
    ),
    target: '.badges',
    disableBeacon: true,
    styles: {
      spotlight: {
        borderRadius: '100%',
      },
    },
    placement: 'bottom-start',
    offset: 20,
  },
  {
    content: (
      <TourTooltipImage
        text="Scroll to your age to find and play your newest gift cards!"
        src={GiftCards}
        alt="Gift Cards"
        width="159.08px"
      />
    ),
    target: '.hey-baby',
    disableBeacon: true,
    styles: {
      spotlight: {
        borderRadius: '30px',
      },
    },
    // placement: 'top-start',
    // offset: 140,
  },
];

function ChildTimeline({
  isKid = false,
  child = undefined,
}: {
  isKid?: boolean;
  child?: UserType | undefined;
}): React.ReactElement {
  const dispatch = useDispatch();
  const { childID } = useParams();
  useBodyClass('parallaxContainer');
  const children = useSelector((state: RootState) => selectChildren(state));
  const giftCards = useSelector((state: RootState) => selectGiftCards(state));
  const currentChild = useMemo(() => {
    if (!child) {
      return children.find((child) => String(child.id) === childID);
    }
    return child;
  }, [children, childID, child]);
  const ageSignRef = useRef<(HTMLSpanElement | null)[]>([]);
  const [isJoyrideFinished, setIsJoyrideFinished] = useState(
    hasCompletedTour(tourPageName),
  );
  useEffect(() => {
    if (!isKid) {
      dispatch(getParentProfile());
    }
  }, [dispatch, isKid]);

  // This use effect is for scrolling to the correct age
  useEffect(() => {
    if (currentChild?.dateOfBirth && isJoyrideFinished) {
      let childAge = calculateAge(currentChild.dateOfBirth);
      if (childAge >= 18) {
        childAge = 18;
      }
      ageSignRef.current[childAge]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentChild, isJoyrideFinished]);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalCard, setModalCard] = React.useState<GiftCardType | null>(null);

  const [modalInfoIsOpen, setInfoIsOpen] = React.useState(false);
  const [modalInfo, setModalInfo] =
    React.useState<InformationParameters | null>(null);

  const [isAtTop, setIsAtTop] = useState<boolean[]>([]);
  const [isAtBottom, setIsAtBottom] = useState<boolean[]>([]);

  function openModal(giftCard: GiftCardType) {
    setIsOpen(true);
    setModalCard(giftCard);
  }

  function closeModal() {
    setIsOpen(false);
    setModalCard(null);
  }

  function openGame() {
    if (!modalCard || !currentChild) {
      return;
    }
    const cardId = modalCard.id;
    closeModal();
    window.open(`/child-timeline/${currentChild.id}/game/${cardId}`);
  }

  const giftCardImageSrc = cardImageFromName(
    String(modalCard?.giftCardGameName),
  );

  const onPressGiftcard = (giftCard: GiftCardType) => {
    openModal(giftCard);
  };

  function openInfoModal(info: InformationParameters) {
    setInfoIsOpen(true);
    setModalInfo(info);
  }

  function closeInfoModal() {
    trackNodeModalClose(modalInfo?.age, modalInfo?.node);
    setInfoIsOpen(false);
    setModalInfo(null);
  }

  const handleOnCta = (age: number, index: number, node: number) => {
    openInfoModal({ age, index, node });
  };

  const [childGiftCards, setChildGiftCards] = useState<GiftCardType[]>([]);

  useEffect(() => {
    if (currentChild) {
      const currChildGiftCards = giftCardsForChild(
        Number(currentChild?.id || 0),
        giftCards,
      );
      setChildGiftCards(currChildGiftCards);
    }
  }, [currentChild, giftCards]);

  const numberOfYears = 19; // inc 0

  useEffect(() => {
    if (isAtTop.length < numberOfYears) {
      const fillArray = Array(numberOfYears).fill(true);
      setIsAtTop(fillArray);
    }
    if (isAtBottom.length < numberOfYears) {
      const fillArray = Array(numberOfYears).fill(false);
      setIsAtBottom(fillArray);
    }
  }, [isAtBottom.length, isAtTop.length]);

  const AgeModal = (isOpen: boolean, onClose: () => void) => {
    if (isOpen) {
      trackNodeModalOpen(modalInfo?.age, modalInfo?.node);
    }
    switch (modalInfo?.age) {
      case 0: {
        if (modalInfo.node === 1) {
          return <ModalA0N1 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 1: {
        if (modalInfo.node === 1) {
          return <ModalA1N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA1N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 2: {
        if (modalInfo.node === 1) {
          return <ModalA2N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA2N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 3: {
        if (modalInfo.node === 1) {
          return <ModalA3N1 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 4: {
        if (modalInfo.node === 1) {
          return <ModalA4N1 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 5: {
        if (modalInfo.node === 1) {
          return <ModalA5N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA5N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 6: {
        if (modalInfo.node === 1) {
          return <ModalA6N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA6N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 7: {
        if (modalInfo.node === 1) {
          return <ModalA7N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA7N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 8: {
        if (modalInfo.node === 1) {
          return <ModalA8N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA8N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 9: {
        if (modalInfo.node === 1) {
          return <ModalA9N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA9N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 10: {
        if (modalInfo.node === 1) {
          return <ModalA10N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA10N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 11: {
        if (modalInfo.node === 1) {
          return <ModalA11N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA11N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 12: {
        if (modalInfo.node === 1) {
          return <ModalA12N1 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 13: {
        if (modalInfo.node === 1) {
          return <ModalA13N1 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 14: {
        if (modalInfo.node === 1) {
          return <ModalA14N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA14N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 15: {
        if (modalInfo.node === 1) {
          return <ModalA15N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA15N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 16: {
        if (modalInfo.node === 1) {
          return <ModalA16N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA16N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 17: {
        if (modalInfo.node === 1) {
          return <ModalA17N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA17N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
      case 18: {
        if (modalInfo.node === 1) {
          return <ModalA18N1 isOpen={isOpen} onClose={onClose} />;
        }
        if (modalInfo.node === 2) {
          return <ModalA18N2 isOpen={isOpen} onClose={onClose} />;
        }
        break;
      }
    }
  };

  if (!currentChild?.id) {
    return <></>;
  }
  return (
    <ContainerComponent>
      <Box zIndex="9999999999999">
        <GiftCardModal
          closeModal={closeModal}
          giftCardImageSrc={giftCardImageSrc}
          modalCard={modalCard}
          modalIsOpen={modalIsOpen}
          openGame={openGame}
        />
      </Box>
      <Box zIndex="9999999999999">
        {AgeModal(modalInfoIsOpen, closeInfoModal)}
      </Box>
      <BaseContainer
        maxW="100%"
        px={['1', '0']}
        centerContent
        data-testid="timeline-header"
      >
        <Box
          // className={styles.kid_timline_wrapper}
          w="100%"
          bg="brand.primaryBackground"
        >
          <WealthieHelmet title="Child Timeline" />

          <Box className="greating-heading">
            {/* TODO: fix header width */}
            <Header showNav showLogoutOnly={isKid} headerStyle="hamburger" />
            <Center className="greating-heading">
              <Heading
                variant="title"
                paddingBottom={['20px', '30px']}
                textAlign="center"
              >
                Hi {currentChild?.firstName}!
              </Heading>
            </Center>
          </Box>
          <HStack
            alignItems="center"
            justifyContent="center"
            marginBottom={['36px', '46px']}
            gap={['30px', '40px']}
          >
            <Box className={styles.child_margin}>
              <WelcomeSlider
                className={`${styles.slider} badges`}
                autoPlay
                isChildTimeline
              />
            </Box>
            <Box className={styles.child_margin}>
              <ChildDetail
                {...currentChild}
                hasEdit={!isKid}
                isShowInformation={false}
                fillContainer
              />
            </Box>
          </HStack>
          <Image src={childDivider} height="20px" width="100%" alt="divider" />
        </Box>
      </BaseContainer>

      <BaseContainer
        maxW="100%"
        px={['1', '0']}
        centerContent
        data-testid="timeline-body"
      >
        <Box
          className={`${styles.wrapper} ${styles.parallax} parallax_container`}
          data-testid="my_kids_page"
          bg="brand.childTimelineBg"
        >
          {[...Array(numberOfYears).fill(1)].map((_, i) => (
            <Box
              className={`${styles.ageBlockContainer} ${
                styles[`ageBlockContainer${i}`]
              }`}
              key={i}
            >
              <Box className={`${styles.ageBlockContent} ${styles.ageBlock}`}>
                <Box className={`${styles.TopTrigger} Top-trigger-${i}`} />
                <Box marginLeft={['', '6.37%']}>
                  <AgeSign age={i} ref={(el) => (ageSignRef.current[i] = el)} />
                </Box>
                <Box className={`${styles.AgeTrigger} Age-trigger-${i}`} />
                <Age age={i} trigger={`.Age-trigger-${i}`} />
                {currentChild && (
                  <Box
                    marginLeft={['', '6.37%']}
                    position={['absolute', 'relative']}
                    maxWidth="fit-content"
                    width={['', '15%']}
                    minWidth={['', '165px', '140px']}
                    paddingY="5px"
                    paddingX="5px"
                  >
                    {!isAtTop[i] &&
                      giftCardsForAge(currentChild, i, childGiftCards).length >
                        5 && (
                        <Box
                          position="absolute"
                          width={['100%', '165px', '165px']}
                          height="50px"
                          backgroundImage="linear-gradient(to top, transparent 25%, rgba(213, 245, 254, 1) 75%)"
                          zIndex={101}
                          top={0}
                          paddingY="5px"
                          paddingX="5px"
                        />
                      )}
                    <Box
                      display="flex"
                      flexDir="column"
                      gap="10px"
                      // add a prop to hide the scrollbar but allow scrolling
                      overflowY={
                        giftCardsForAge(currentChild, i, childGiftCards)
                          .length > 4
                          ? 'scroll'
                          : 'hidden'
                      }
                      overflowX="hidden"
                      maxHeight={['375px', '425px', '500px']}
                      // maxWidth="fit-content"
                      width={['fit-content', 'fit-content', '195px']}
                      paddingY="5px"
                      paddingX="5px"
                      position="relative"
                      zIndex={100}
                      css={{
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        '&::-webkit-scrollbar-track': {
                          width: '6px',
                        },
                        // '&::-webkit-scrollbar-thumb': {
                        //   background: scrollbarColor,
                        //   borderRadius: '24px',
                        // },
                      }}
                      onScroll={(event) => {
                        const {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          scrollTop,
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          scrollHeight,
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          clientHeight,
                        } = event.target;

                        // Check if at the top
                        if (scrollTop === 0) {
                          setIsAtTop((prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[i] = true;
                            return updatedValue;
                          });
                        } else {
                          setIsAtTop((prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[i] = false;
                            return updatedValue;
                          });
                        }

                        // Check if at the bottom
                        if (scrollHeight - scrollTop === clientHeight) {
                          setIsAtBottom((prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[i] = true;
                            return updatedValue;
                          });
                        } else {
                          setIsAtBottom((prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[i] = false;
                            return updatedValue;
                          });
                        }
                      }}
                    >
                      <AgeGiftCards
                        age={i}
                        child={currentChild}
                        giftCards={childGiftCards}
                        onPress={onPressGiftcard}
                      />
                    </Box>
                    {!isAtBottom[i] &&
                      giftCardsForAge(currentChild, i, childGiftCards).length >
                        4 && (
                        <Box
                          position="absolute"
                          width={['100%', '165px', '165px']}
                          height="50px"
                          backgroundImage="linear-gradient(to bottom, transparent 25%, rgba(213, 245, 254, 1) 75%)"
                          zIndex={100}
                          bottom={0}
                          paddingY="5px"
                          paddingX="5px"
                        />
                      )}
                  </Box>
                )}
                <BackgroundLayer age={i} level={0} onCta={handleOnCta} />
                <Box className={styles.backgroundLayerContainer}>
                  <BackgroundLayer age={i} level={1} onCta={handleOnCta} />
                  <BackgroundLayer age={i} level={2} onCta={handleOnCta} />
                  <BackgroundLayer age={i} level={3} onCta={handleOnCta} />
                  <BackgroundLayer age={i} level={4} onCta={handleOnCta} />
                  <BackgroundLayer age={i} level={5} onCta={handleOnCta} />
                </Box>
              </Box>
            </Box>
          ))}
          {/* <Box className={styles.ageBlockContainer} key="last">
            <Box
              className={`${styles.ageBlockContent} ${styles.ageBlock}`}
            ></Box>
          </Box> */}
        </Box>
      </BaseContainer>
      {!hasCompletedTour(tourPageName) && (
        <ReactJoyride
          steps={tourSteps}
          continuous
          run
          tooltipComponent={TourTooltip}
          //          disableScrolling
          callback={(data) => {
            handleJoyrideCallbackAnalytics(data, tourPageName);
            handleCreateJoyrideCookies(data, tourPageName);
            const { status } = data;
            if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
              setIsJoyrideFinished(true);
            }
          }}
          spotlightPadding={0}
          styles={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
            },
            spotlight: {
              backgroundColor: '#999',
            },
          }}
          disableOverlayClose
        />
      )}
    </ContainerComponent>
  );
}

export default ChildTimeline;
