import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Avatar,
  AvatarBadge,
  Box,
  Heading,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { setCurrentChild, UserType } from '../../store/reducers/homeSlice';
import AvatarIcon from '../AvatarPicker/AvatarIcon';
import EditIcon from '../EditIcon';

export interface ChildDetailProps extends UserType {
  img?: string;
  isLarge?: boolean;
  hasEdit?: boolean;
  isShowInformation?: boolean;
  fillContainer?: boolean;
  enableClickKid?: boolean;
  kid?: UserType;
}

const largeAvatarDesktop = '180px';
const largeAvatar = 144;
const smallAvatar = 70;

function ChildDetail(props: ChildDetailProps): React.ReactElement {
  const {
    firstName = '',
    lastName = '',
    dateOfBirth = '',
    avatarId = 0,
    img = null,
    kid = null,
    isLarge = true,
    hasEdit = false,
    isShowInformation = true,
    fillContainer = false,
    enableClickKid = false,
    id,
    parentId,
  } = props;
  const fullName = useMemo(
    () => `${firstName || ''} ${lastName || ''}`,
    [firstName, lastName],
  );

  const history = useHistory();
  const dispatch = useDispatch();

  let avatarImage = img;

  if (avatarId) {
    avatarImage = AvatarIcon.resolveAvatar(avatarId);
  } else {
    avatarImage = AvatarIcon.resolveAvatar(1);
  }

  const onClickEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/edit-child-profile/${parentId}/${id}`);
  };

  const onClickViewKid = useCallback(
    (e) => {
      if (kid && enableClickKid) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setCurrentChild({ kid }));
        history.push({
          pathname: `/child-timeline/${id}`,
        });
      }
    },
    [dispatch, history, kid, enableClickKid, id],
  );

  const EditIconButton = () => (
    <IconButton
      aria-label="Edit"
      variant=""
      position="absolute"
      bottom="-5px"
      right="-5px"
      onClick={onClickEdit}
      icon={<EditIcon />}
    />
  );

  return (
    <VStack
      data-testid="child_detail"
      marginBottom={isShowInformation ? '30px' : '0px'}
    >
      {/* Avatar Outline Box */}
      <Box
        border="3px solid var(--chakra-colors-brand-primaryBackground)"
        background="var(--chakra-colors-brand-primaryBackground)"
        filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
        borderRadius="147px"
      >
        <Avatar
          w={[
            fillContainer ? '100%' : isLarge ? largeAvatar : smallAvatar,
            fillContainer ? '100%' : isLarge ? largeAvatarDesktop : smallAvatar,
          ]}
          h={[
            fillContainer ? '100%' : isLarge ? largeAvatar : smallAvatar,
            fillContainer ? '100%' : isLarge ? largeAvatarDesktop : smallAvatar,
          ]}
          src={avatarImage}
          onClick={(e) => enableClickKid && onClickViewKid(e)}
          cursor={enableClickKid ? 'pointer' : 'default'}
          background="linear-gradient(180deg, rgba(251, 251, 251, 1) 0%, var(--chakra-colors-brand-primaryBackground) 100%)"
          border="2px solid"
          sx={{
            borderImageSource:
              'linear-gradient(180deg, rgba(251, 251, 251, 1) 0%, var(--chakra-colors-brand-primaryBackground) 100%)',
          }}
          dropShadow="0px 2.5899999141693115px 2.1600000858306885px 1.1404273509979248px rgba(0, 0, 0, 0.05)"
          boxShadow="0px 2.59px 2.16px 1.14043px rgba(0, 0, 0, 0.05);"
        >
          <AvatarBadge border="0">{hasEdit && <EditIconButton />}</AvatarBadge>
        </Avatar>
      </Box>
      <Box>
        {isShowInformation && (
          <Heading
            textAlign="center"
            mt="4px"
            variant="small"
            data-testid="child_detail_name"
            fontWeight="medText"
            fontSize="smallTitle"
            lineHeight="medText"
          >
            {fullName}
          </Heading>
        )}
        {isShowInformation && (
          <Box>
            <Text
              variant="small"
              textAlign="center"
              data-testid="child_detail_dob"
              fontSize="smallTitle"
              fontWeight="smallText"
              lineHeight="medText"
            >{`Birthday: ${
              dateOfBirth
                ? moment(new Date(dateOfBirth))
                    .utc(false)
                    .format('MMMM DD, YYYY')
                : ''
            }`}</Text>
          </Box>
        )}
      </Box>
    </VStack>
  );
}

export default ChildDetail;
