import React from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

const BodyContainer: React.FC<ContainerProps> = (props: ContainerProps) => (
  <Container
    paddingX={['15px', '15px']}
    maxWidth={['100%', '650px']} // 620 + 2x15
    {...props}
  />
);

export default BodyContainer;
