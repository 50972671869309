import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const UpIcon = createIcon({
  displayName: 'BackIcon',
  viewBox: '0 0 30 17',
  defaultProps: {
    w: '30px',
    h: '17px',
    mr: '0.25rem',
    color: 'brand.primary',
    fill: 'none',
  },
  path: (
    <>
      <path d="M28 15L15 3L2 15" stroke="#00324D" strokeWidth="3" />
    </>
  ),
});

export default UpIcon;
