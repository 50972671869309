import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { ReactComponent as WealthieWorksImage } from '../../assets/images/header/wealthie-works.svg';
import { ReactComponent as WealthieWorksLargeImage } from '../../assets/images/header/wealthie-works-large.svg';
import { USE_MEDIA_QUEREY_VALUE } from '../../constants/variables';

function HeaderTitle({ children }: any): React.ReactElement {
  const history = useHistory();

  const [isDesktopSize] = useMediaQuery(USE_MEDIA_QUEREY_VALUE);

  return (
    <Box
      onClick={() => {
        history.replace('/');
      }}
      maxW={['255px', '1000px']}
      marginLeft={['0', '50px']}
      width="100%"
      cursor="pointer"
    >
      {isDesktopSize ? (
        <WealthieWorksLargeImage width={'100%'} />
      ) : (
        <WealthieWorksImage width={'255px'} />
      )}
      {children}
    </Box>
  );
}

export default HeaderTitle;
