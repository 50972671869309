import React from 'react';
import { Box, AccordionButton, Icon } from '@chakra-ui/react';

interface Props {
  title: string;
  isExpanded: boolean;
}

export function FormAccordionButton({
  title,
  isExpanded,
}: Props): React.ReactElement<Props> {
  return (
    <AccordionButton
      fontWeight="medText"
      fontSize="subTitle"
      lineHeight="medText"
      padding="0px"
    >
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>{title}</Box>
        {isExpanded ? (
          <Icon>
            <path d="M-1.63477e-07 10H20" stroke="#00324E" strokeWidth="3" />
          </Icon>
        ) : (
          <Icon height="20px" width="20px">
            <path
              d="M10 20.0005V0.000488792"
              stroke="#00324E"
              strokeWidth="3"
            />
            <path
              d="M-1.63477e-07 9.99951H20"
              stroke="#00324E"
              strokeWidth="3"
            />
          </Icon>
        )}
      </Box>
    </AccordionButton>
  );
}
