import React, { useRef } from 'react';
import { Accordion, AccordionItem, AccordionButton } from '@chakra-ui/react';
import { Box, Heading } from '@chakra-ui/layout';
import AgeBlock from './ages/AgeBlock';
import { ReactComponent as ViewMoreIcon } from '../../assets/images/icons/view-more.svg';
import { ReactComponent as CollapsibleIcon } from '../../assets/images/icons/collapsible.svg';
import {
  trackAgeSectionClosed,
  trackAgeSectionOpen,
} from '../../lib/analytics';

const menuList = Array(19)
  .fill(0)
  .map((_, index) => {
    let title = '';
    const key = index;
    switch (index) {
      case 0:
        title = 'Hey Baby!';
        break;
      case 1:
        title = '1 Year Old';
        break;
      case 18:
        title = '18+ Years Old';
        break;
      default:
        title = `${index} Years Old`;
    }
    return {
      key,
      title,
    };
  });

const buttonPaddingExpanded = '26px';
const buttonPaddingClosed = '11px';

function AgeMenu(): React.ReactElement {
  const itemsRefs = useRef<HTMLDivElement | null[]>([]);
  const onAccordionChange = (index: number) => {
    if (index < 0) {
      trackAgeSectionClosed();
      return;
    }
    trackAgeSectionOpen(index);
    setTimeout(() => {
      itemsRefs.current[index].scrollIntoView({
        behavior: 'smooth',
        alignToTop: true,
      });
    }, 10);
  };

  return (
    <Box>
      <Accordion
        allowToggle
        allowMultiple={false}
        onChange={onAccordionChange}
        reduceMotion
      >
        {menuList.map((item, index) => (
          <AccordionItem
            key={item.key}
            ref={(el) => (itemsRefs.current[index] = el)}
            py={0}
            // my={['20px', '30px']}
            // onChange={(event) => {}}
            // borderStyle="none"
            data-testid="accordion-item"
          >
            {({ isExpanded }) => (
              <>
                <Box
                  bgColor={isExpanded ? 'brand.lightBackground' : ''}
                  borderTopRadius="8px"
                  // borderStyle="none"
                  borderColor={isExpanded ? 'brand.titleHighlight' : ''}
                  borderTopWidth={isExpanded ? '2px' : ''}
                  borderLeftWidth={isExpanded ? '2px' : ''}
                  borderRightWidth={isExpanded ? '2px' : ''}
                  borderTopLeftRadius={isExpanded ? '8px' : ''}
                >
                  <Heading as="h2">
                    <AccordionButton
                      justifyContent="end"
                      alignItems={isExpanded ? 'end' : 'center'}
                      py={
                        isExpanded ? buttonPaddingExpanded : buttonPaddingClosed
                      }
                      paddingTop={
                        isExpanded ? buttonPaddingExpanded : buttonPaddingClosed
                      }
                      px="15px"
                    >
                      {!isExpanded ? (
                        <>
                          <Box flex="1" textAlign="left">
                            <Heading
                              variant="subTitleSection"
                              textTransform="uppercase"
                            >
                              {item.title}
                            </Heading>
                          </Box>
                          <ViewMoreIcon />
                        </>
                      ) : (
                        <Box>
                          <CollapsibleIcon />
                        </Box>
                      )}
                    </AccordionButton>
                  </Heading>
                </Box>
                <AgeBlock age={item.key} />
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}

export default AgeMenu;
