import { trackLogin, trackLogout } from './../../lib/analytics';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import axios from 'axios';
import { get } from 'lodash';

import { AppThunk } from '..';
import showNotification, { Messages } from '../../components/Notification';
import { acceptInviteApi } from '../../apis';

import { RootState } from '.';

export interface UserAttributes {
  sub: string;
  email: string;
  email_verified: string;
  name: string;
  updatedAt: string;
}

type WealthieCognitoUser = CognitoUser & UserAttributes;

interface AuthState {
  user: WealthieCognitoUser | undefined;
  isAuthenticated: boolean;
  currentGiftCardId: string;
  isLoading: boolean;
  loginReason?: string;
}

const initialState: AuthState = {
  user: undefined,
  isAuthenticated: false,
  currentGiftCardId: '',
  isLoading: false,
  loginReason: 'standard',
};

type LoginPayload = {
  user: WealthieCognitoUser;
  loginReason?: string;
};

type GiftCardIdPayload = {
  giftCardId: string;
};

const authSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<LoginPayload>) {
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
        isLoading: false,
        loginReason: action.payload.loginReason
          ? action.payload.loginReason
          : 'standard',
      };
    },
    loginBegin(state) {
      state.isLoading = true;
      return state;
    },
    loginComplete(state) {
      state.isLoading = false;
      return state;
    },
    logout() {
      return initialState;
    },
    setCurrentGiftId(state, action: PayloadAction<GiftCardIdPayload>) {
      return { ...state, currentGiftCardId: action.payload.giftCardId };
    },
    clearLoginReason(state) {
      state.loginReason = 'standard';
      return state;
    },
  },
});

export const {
  clearLoginReason,
  loginSuccess,
  logout,
  setCurrentGiftId,
  loginBegin,
  loginComplete,
} = authSlice.actions;

export const isKid = (user: WealthieCognitoUser | undefined): boolean =>
  !!user && get(user, 'attributes.name', false);

export const currentGiftIdSelector = createSelector(
  (state: RootState) => state.authReducer,
  (authReducer: AuthState) => authReducer.currentGiftCardId,
);

export const isKidAuthSelector = createSelector(
  (state: RootState) => state.authReducer,
  (authReducer: AuthState) => isKid(authReducer.user),
);

export const selectIsLoading = createSelector(
  (state: RootState) => state.authReducer,
  (authReducer: AuthState) => authReducer.isLoading,
);

export const logoutWealthie = (): AppThunk => async (dispatch) => {
  await Auth.signOut();
  dispatch(logout());
  trackLogout();
};

export const verifyInvitation =
  (
    inviteToken: string,
    history?: any,
    silent?: boolean,
    path?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await acceptInviteApi({ invite_token: inviteToken });
      // eslint-disable-next-line no-console
      console.debug('accepted invite', res);
      dispatch(setCurrentGiftId({ giftCardId: res.data?.giftCardId }));
      localStorage.removeItem('@invite');
      if (!silent) {
        showNotification({
          type: 'success',
          message: Messages.verificationSucceeded,
        });
      }
      // push to history if params has history
      // this is essentially a reload as the login (user authenticated event) will trigger a route to /home or /assign-gift-card
      if (history) {
        // eslint-disable-next-line no-console
        console.debug('opening history');
        const params = new URLSearchParams();
        // params.append('src', 'accept');
        // params.append('t', Date.now().toString());
        const pathname = path ? path : '/home';
        dispatch(clearLoginReason());
        // eslint-disable-next-line no-console
        console.debug(
          `verifyInvitation pathname: ${pathname}, search: ${params.toString()}`,
        );
        setTimeout(() => {
          history.push({
            pathname,
            state: {
              giftId: res.data?.giftCardId,
              gift: res.data,
              t: Date.now().toString(),
            },
            //          search: params.toString(),
          });
        }, 0);
      }
    } catch (error) {
      console.error('verify error', error);
      localStorage.removeItem('@invite');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if ('warning' in error?.response?.data) {
        showNotification({
          type: 'warning',
          message: get(
            error,
            'response.data.warning.message',
            Messages.verificationFailed,
          ),
          showTitle: false,
        });
      } else {
        showNotification({
          type: 'error',
          message: get(
            error,
            'response.data.error.message',
            Messages.verificationFailed,
          ),
        });
      }
      if (history) {
        history.push('/');
      }
    }
  };

export const loginWealthie =
  (
    userName: string,
    password: string,
    history: any,
    reason?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      if (!userName || !password) {
        throw new Error("Username or Password can't be empty");
      }
      // alert(`login wel.  reason is:${reason}`);
      dispatch(loginBegin());
      const inviteCode = await localStorage.getItem('@invite');
      const user = await Auth.signIn(userName, password, {
        appId: 'works.wealthie.auth',
      });
      const token = get(user, 'signInUserSession.accessToken.jwtToken', '');
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch(loginComplete());
      dispatch(loginSuccess({ user, loginReason: reason }));
      trackLogin();
      // TODO: refactor this.  writer a helper function at least
      const historyPath = reason === 'invite' ? '/assign-gift-cards' : '/home';
      if (isKid(user)) {
        history.push(historyPath);
      } else {
        if (inviteCode) {
          // don't navigate, it is done in verifyinvitation (or skipped if history is null)
          // this is essentially a "re-nagivate"
          if (reason) {
            setTimeout(() => {
              dispatch(
                verifyInvitation(inviteCode, history, true, historyPath),
              );
            }, 0);
          } else {
            dispatch(verifyInvitation(inviteCode, history, false));
          }
        }
      }
    } catch (error) {
      console.error('error with login wealthie', error);
      dispatch(loginComplete());
      const errorObject: any = error;
      if (errorObject.name && errorObject.name === 'NotAuthorizedException') {
        showNotification({
          type: 'error',
          message: errorObject.message,
        });
      } else {
        showNotification({
          type: 'error',
          message: Messages.somethingWentWrong,
        });
      }
    }
  };

export default authSlice.reducer;
