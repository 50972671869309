import React from 'react';
import { Container } from '@chakra-ui/react';

const ContainerComponent: React.FC = ({ children }: any) => (
  <Container paddingX="15px" maxWidth={['375px', '800px']}>
    {children}
  </Container>
);

export default ContainerComponent;
