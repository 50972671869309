import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ChakraProvider } from '@chakra-ui/react';
import { createBrowserHistory } from 'history';

import LoginPage from './pages/Login';
import ForgotPwdPage from './pages/ForgotPwd';
import ResetPwd from './pages/ResetPwd';
import Register from './pages/Register';
import ChildSignUp from './pages/ChildSignUp';
import EditProfile from './pages/EditProfile';
import EditChildProfile from './pages/EditChildProfile';
import LandingPage from './pages/Landing';
import MyKids from './pages/MyKids';
import ChildTimeline from './pages/ChildTimeline';
import ChildTimelineDev from './pages/ChildTimeline/dev';
import ChildGame from './pages/ChildGame';
import GiftCards from './pages/GiftCards';
import VerifyInvitation from './pages/VerifyGiftInvitation';
import PrivacyPolicy from './pages/PrivacyPolicy';
// import Email from './pages/Email';
import AppStore from './store';
import PrivateRoute from './components/Authentication/PrivateRoute';
import PublicRoute from './components/Authentication/PublicRoute';
import GiftAssignScreen from './pages/VerifyGiftInvitation/GiftAssignScreen';
// import GiftTestScreen from './pages/GiftCards/TestGiftCard';
import theme from './theme';
import Fonts from './theme/fonts';

import './index.scss';
import VideoPlayerTest from './pages/GiftCardPlayer';
import InvestGiftCardScreen from './pages/InvestGiftCard/InvestGiftCardScreen';

const history = createBrowserHistory();

function App(): React.ReactElement {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Provider store={AppStore.store}>
        <PersistGate loading={null} persistor={AppStore.persistor}>
          <Router history={history}>
            <Switch>
              <PrivateRoute
                exact
                isVerifyRoute
                path="/invite/:inviteToken"
                component={VerifyInvitation}
              />
              <PrivateRoute
                isParentOnly={false}
                exact
                path="/home"
                component={LandingPage}
              />
              <PublicRoute exact path="/" component={LoginPage} />
              <PublicRoute exact path="/forgot-pwd" component={ForgotPwdPage} />
              <PublicRoute exact path="/reset-pwd" component={ResetPwd} />
              <PublicRoute exact path="/register" component={Register} />
              <PublicRoute
                exact
                path="/privacy-policy"
                component={PrivacyPolicy}
              />
              {/* TODO: Change to private route */}
              {/* <PublicRoute exact path="/email" component={Email} /> */}
              <PrivateRoute
                exact
                path="/gift-card-player"
                component={VideoPlayerTest}
              />
              <PrivateRoute
                exact
                path="/child-sign-up"
                component={ChildSignUp}
              />
              {/* <PrivateRoute
                exact
                path="/invest-gift-card"
                component={InvestGiftCardScreen}
              /> */}
              <PrivateRoute
                exact
                path="/invest-gift-card/:childId"
                component={InvestGiftCardScreen}
              />
              {/* <PrivateRoute exact path="/profile" component={Profile} /> */}
              <PrivateRoute exact path="/my-kids" component={MyKids} />
              <PrivateRoute exact path="/gift-cards" component={GiftCards} />
              <PrivateRoute
                exact
                path="/assign-gift-cards"
                component={GiftAssignScreen}
              />
              <PrivateRoute
                exact
                path="/edit-profile/:parentId"
                component={EditProfile}
              />
              <PrivateRoute
                exact
                path="/edit-child-profile/:parentId/:childId"
                component={EditChildProfile}
              />
              {/*<PrivateRoute
                exact
                path="/child-detail/:childID"
                component={ChildDetail}
            />*/}
              <PrivateRoute
                exact
                path="/child-timeline/:childID"
                component={ChildTimeline}
              />
              <PrivateRoute
                exact
                path="/child-timeline-dev/:childID"
                component={ChildTimelineDev}
              />
              <PrivateRoute
                exact
                isParentOnly={false}
                path="/child-timeline/:childID/game/:cardId"
                component={ChildGame}
              />

              {/* <PublicRoute
                allowAuth
                exact
                path="/gift/test"
                component={GiftTestScreen}
              /> */}
              {/* Redirect when route not found */}
              <Redirect to="/home" />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
