import React from 'react';
import { Text, Icon, Box } from '@chakra-ui/react';

interface PasswordValidationTextProps {
  isValid: boolean;
  text: string;
}

const PasswordValidationText: React.FunctionComponent<
  PasswordValidationTextProps
> = ({ isValid, text }) => (
  <Box
    display="flex"
    fontWeight="medText"
    fontSize="extraSmallText"
    lineHeight="extraSmallText"
    alignItems="center"
    gap="5px"
  >
    {isValid ? (
      <>
        <Icon
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 7.5C14.5 11.366 11.366 14.5 7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 3.63401 3.63401 0.5 7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5Z"
            fill="white"
            stroke="#00324E"
          />
          <path
            d="M3 7.38L6.12 10.5L12.12 4.5"
            stroke="#00324E"
            strokeWidth="1.5"
          />
        </Icon>
        <Text textColor="rgba(0, 50, 78, 1)">{text}</Text>
      </>
    ) : (
      <>
        <Icon
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 7.5C14.5 11.366 11.366 14.5 7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 3.63401 3.63401 0.5 7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5Z"
            fill="white"
            stroke="#808080"
          />
          <path
            d="M3 7.38L6.12 10.5L12.12 4.5"
            stroke="#808080"
            strokeWidth="1.5"
          />
        </Icon>
        <Text textColor="rgba(128, 128, 128, 1)">{text}</Text>
      </>
    )}
  </Box>
);

export default PasswordValidationText;
