import React from 'react';
import PasswordValidationText from './PasswordValidationText';
import { Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

interface Props {
  passwordValue: string;
}

function PasswordValidationPrompts({
  passwordValue,
}: Props): React.ReactElement<Props> {
  return (
    <Box display="flex" flexDir="column" gap="2px" marginTop="10px">
      <PasswordValidationText
        isValid={!isEmpty(passwordValue) && passwordValue.length >= 8}
        text="At least 8 characters"
      />
      <PasswordValidationText
        isValid={!isEmpty(passwordValue) && /[A-Z]/.test(passwordValue)}
        text="At least 1 uppercase character"
      />
      <PasswordValidationText
        isValid={!isEmpty(passwordValue) && /[a-z]/.test(passwordValue)}
        text="At least 1 lowercase character"
      />
      <PasswordValidationText
        isValid={!isEmpty(passwordValue) && /[\W_]/.test(passwordValue)}
        text="At least 1 special character (e.g: !, #, $, etc.)"
      />
    </Box>
  );
}

export default PasswordValidationPrompts;
