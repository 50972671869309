import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const BackIconLarge = createIcon({
  displayName: 'BackIconLarge',
  viewBox: '0 0 21 39',
  defaultProps: {
    w: '21px',
    h: '39px',
    mr: '0.25rem',
    color: 'brand.primary',
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M19 2.0013L3 19.3346L19 36.668"
        stroke="#00324E"
        strokeWidth="4"
      />
    </>
  ),
});

export default BackIconLarge;
