import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from '@chakra-ui/react';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import Header from '../../layout/Header/Header';
import {
  getParentProfile,
  selectParent,
  updateParent,
} from '../../store/reducers/homeSlice';
import { RootState } from '../../store/reducers';
import ActivityIndicator from '../../components/ActivityIndicator';
import Container from '../../components/Container';
import SectionContainer from '../../components/SectionContainer';
import FormStack from '../../components/FormStack';
import { passwordValidationNotRequired } from '../../lib/passwordValidation';
import { reCaptchaKey } from '../../apis';
import BodyContainer from '../../components/BodyContainer';
import PasswordInput from '../../components/PasswordInput';
import PasswordValidationPrompts from '../Register/PasswordValidationPrompts';
import WealthieHelmet from '../../components/WealthieHelmet';

const EditSchema = Yup.object().shape({
  //TODO: refactor
  firstName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Must only be alphanumeric')
    .required('Required'),
  ...passwordValidationNotRequired,
});

function EditProfile(): React.ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();
  const parent = useSelector((state: RootState) => selectParent(state));
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    dispatch(getParentProfile());
  }, [dispatch]);

  const onSubmit = useCallback(
    async ({ firstName, lastName, password }, actions) => {
      if (!executeRecaptcha) {
        actions.setErrors({
          firstName: 'Error with form.  Please reload and try again',
        });
        return false;
      }
      const recaptchaToken = await executeRecaptcha('editProfile');

      dispatch(
        updateParent(
          {
            firstName,
            lastName,
            password: password || undefined,
            token: recaptchaToken,
          },
          history,
        ),
      );
    },
    [dispatch, executeRecaptcha, history],
  );

  const isSaving = parent.isLoading;

  return (
    <Container data-testid="edit_profile_page">
      <WealthieHelmet title="Edit Profile" />
      <Header headerStyle="backArrow" showNav />
      <SectionContainer>
        {/* Temporarily using Heading instead of FormHeading just to show off the stlye change */}
        {/* <FormHeading title="Update your profile" /> */}
        <Heading
          as="h1"
          fontSize="largeText"
          fontWeight="largeText"
          lineHeight="title"
          textAlign="center"
          marginBottom="20px"
        >
          Edit Parent Account
        </Heading>
      </SectionContainer>
      <BodyContainer>
        <Formik
          initialValues={{
            firstName: parent.firstName ? parent.firstName : '',
            lastName: parent.lastName ? parent.lastName : '',
            email: parent.email ? parent.email : '',
            password: '',
            passwordRepeat: '',
          }}
          onSubmit={onSubmit}
          validationSchema={EditSchema}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormStack data-testid="edit_profile_form">
                <Field name="firstName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.firstName && form.touched.firstName
                      }
                    >
                      <FormLabel htmlFor="firstName">First Name</FormLabel>
                      <Input
                        {...field}
                        id="firstname-id"
                        data-testid="firstname-id"
                        type="text"
                        autoComplete="given-name"
                        placeholder="First Name"
                      />
                      <FormErrorMessage>
                        {form.errors.firstName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.lastName && form.touched.lastName}
                    >
                      <FormLabel htmlFor="lastName">Last Name</FormLabel>
                      <Input
                        {...field}
                        id="lastname-id"
                        data-testid="lastname-id"
                        type="text"
                        autoComplete="family-name"
                        placeholder="Last Name"
                      />
                      <FormErrorMessage>
                        {form.errors.lastName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel htmlFor="email">Email Address</FormLabel>
                      <Input
                        {...field}
                        id="email-input"
                        type="email"
                        data-testid="email-address"
                        placeholder="Email Address"
                        disabled
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel>Password</FormLabel>
                      <PasswordInput
                        {...field}
                        id="password-id"
                        autoComplete="new-password"
                        placeholder="New Password"
                        data-testid="password-id"
                      />
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                      <PasswordValidationPrompts passwordValue={field.value} />
                    </FormControl>
                  )}
                </Field>
                <Field name="passwordRepeat">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.passwordRepeat &&
                        form.touched.passwordRepeat
                      }
                    >
                      <FormLabel htmlFor={field.name}>
                        Confirm Password
                      </FormLabel>
                      <PasswordInput
                        {...field}
                        id="passwordRepeat-id"
                        autoComplete="new-password"
                        placeholder="Confirm Password"
                      />
                      <FormErrorMessage>
                        {form.errors.passwordRepeat}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Center>
                  <Button
                    isLoading={formik.isSubmitting && isSaving}
                    disabled={!formik.isValid}
                    type="submit"
                    data-testid="submit-button"
                    colorScheme="accent"
                    variant="action"
                  >
                    SAVE CHANGES
                  </Button>
                </Center>
              </FormStack>
            </Form>
          )}
        </Formik>
        {isSaving && <ActivityIndicator tip="Working" />}
      </BodyContainer>
    </Container>
  );
}

const EditProfileWrapper = (props: any): React.ReactElement => (
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
    <EditProfile {...props} />
  </GoogleReCaptchaProvider>
);

export default EditProfileWrapper;
