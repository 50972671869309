import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import {
  Box,
  Button,
  Heading,
  ModalBody,
  ModalFooter,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

import Header from '../../layout/Header/Header';
import ChildDetail from '../../components/ChildDetail';

import {
  getParentProfile,
  GiftCardType,
  selectChildren,
  selectGiftCards,
  selectParent,
  UserType,
} from '../../store/reducers/homeSlice';

import styles from './styles.module.scss';
import { assignGiftCardApi } from '../../apis';
import showNotification, { Messages } from '../../components/Notification';
import Container from '../../components/Container';
import ActivityIndicator from '../../components/ActivityIndicator';
import { cardImageFromName } from '../ChildTimeline/images';
import AddChildText from '../../components/AddChildText';
import ItemWithInfo from '../../components/ItemWithInfo';
import CustomModal from '../../components/CustomModal';
import GiftCardImage from '../../components/GiftCardImage';
import {
  trackAssignGiftcard,
  trackAssignModalClose,
  trackAssignModalOpen,
} from '../../lib/analytics';
import WealthieHelmet from '../../components/WealthieHelmet';
import { RootState } from '../../store/reducers';
import { USE_MEDIA_QUEREY_VALUE } from '../../constants/variables';

const maxGiftcardImageCount = 3;

function GiftAssignScreen(): React.ReactElement {
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const children = useSelector((state: RootState) => selectChildren(state));
  const parent = useSelector((state: RootState) => selectParent(state));

  const giftcards = useSelector((state: RootState) => selectGiftCards(state));
  const { parentGiftCards, isLoading } = parent;
  const [currentChild, setCurrentChild] = useState<UserType | null>(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isLastCard, setIsLastCard] = useState(false);

  const [isDesktopSize] = useMediaQuery(USE_MEDIA_QUEREY_VALUE);

  //TODO: check invite token and disable assign button if dont have invite token
  // TODO: get it from invite api
  const giftId: number | undefined = useMemo(
    // () => get(location, 'state.giftId'),
    () => {
      if (parentGiftCards && parentGiftCards[0]) {
        return parentGiftCards[0].id;
      }
    },
    [parentGiftCards],
    // [location],
  );

  const gift: GiftCardType | undefined = useMemo(
    () => {
      if (parentGiftCards && parentGiftCards[0]) {
        return parentGiftCards[0];
      }
    },
    // () => get(location, 'state.gift'),
    [parentGiftCards],
    // [location],
  );

  const timeStamp = useMemo(() => {
    if (state && state.t) {
      return state.t;
    }
    return null;
  }, [state]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.debug(`assign timeStamp state is:${timeStamp}`);
    dispatch(getParentProfile());
  }, [dispatch, timeStamp]);

  const showModal = (currentChild: UserType) => () => {
    setCurrentChild(currentChild);
    setConfirmModalVisible(true);
    trackAssignModalOpen();
  };

  const handleOk = useCallback(async () => {
    setConfirmLoading(true);
    try {
      const params = { giftcard_id: giftId, child_id: currentChild?.id };
      await assignGiftCardApi(params);
      history.replace();
      setConfirmModalVisible(false);
      trackAssignModalClose();
      setConfirmLoading(false);

      dispatch(getParentProfile());
      trackAssignGiftcard();
      if (giftcards.length === 0) {
        history.push(`/invest-gift-card/${currentChild?.id}`);
      } else {
        setIsSuccessVisible(true);
        if (parentGiftCards && parentGiftCards.length > 1) {
          setIsLastCard(false);
        } else {
          setIsLastCard(true);
        }
      }
    } catch (error) {
      history.replace();
      setConfirmLoading(false);
      setConfirmModalVisible(false);
      trackAssignModalClose();
      showNotification({
        type: 'error',
        message: get(
          error,
          'response.data.error.message',
          Messages.somethingWentWrong,
        ),
      });
    }
  }, [
    giftId,
    currentChild,
    history,
    dispatch,
    parentGiftCards,
    giftcards.length,
  ]);

  const handleCancel = () => {
    setConfirmModalVisible(false);
    trackAssignModalClose();
  };

  const handleComplete = () => {
    setIsSuccessVisible(false);
    if (isLastCard) {
      history.push('/home');
    }
  };

  const onClickAddChild = () => {
    history.push('/child-sign-up');
  };

  const KidSection = ({ kid, isEnable }) => (
    <Box className={styles.child_detail_wrapper}>
      <ChildDetail isLarge {...kid} kid={kid} enableClickKid />
      <Button disabled={!isEnable} onClick={showModal(kid)} variant="action">
        ASSIGN GIFT CARD
      </Button>
    </Box>
  );

  const giftCardSpacing = 15.18;

  const UnassignedGiftCardImages = parentGiftCards?.map((currGiftCard, idx) => {
    if (idx >= maxGiftcardImageCount) {
      return null;
    }

    const giftCardImageSrc = cardImageFromName(
      String(currGiftCard.giftCardGameName),
    );

    const topValue = 50 + idx * giftCardSpacing;

    return (
      <Box
        key={idx}
        zIndex={parentGiftCards.length - idx}
        top={`-${topValue}px`}
        left="28px"
        position="absolute"
      >
        <GiftCardImage
          giftCardImageSrc={giftCardImageSrc}
          size="lg"
          isRotated
        />
      </Box>
    );
  });

  const assignCardMarginTop = () => {
    let margin: number;
    const modalToTileMargin = isDesktopSize ? 80 : 60;
    if (parentGiftCards && parentGiftCards.length <= maxGiftcardImageCount) {
      margin =
        modalToTileMargin + giftCardSpacing * (parentGiftCards.length - 1);
    } else {
      margin =
        modalToTileMargin + giftCardSpacing * (maxGiftcardImageCount - 1);
    }
    return margin;
  };

  return (
    <Container data-testid="gift_assign_page">
      <WealthieHelmet title="Assign Giftcard" />
      <Header showNav headerStyle="backArrow" />
      <CustomModal isOpen={confirmModalVisible} onClose={handleCancel}>
        <ModalBody p="0px" mb="30px">
          <Box
            fontSize="smallTitle"
            lineHeight="medText"
            fontWeight="smallText"
          >
            Confirm that you&apos;re assigning this Gift Card to{' '}
            <Text fontWeight="medText">
              {currentChild?.firstName} {currentChild?.lastName}!
            </Text>
            <br />
            This action can&apos;t be undone.
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="space-between" alignItems="center" p="0px">
          <Button mr={3} size={'md'} onClick={handleOk} variant="action">
            CONFIRM
          </Button>
          <Button
            mr={3}
            size={'md'}
            onClick={handleCancel}
            variant="text"
            fontSize="smallTitle"
            lineHeight="medText"
            fontWeight="smallText"
          >
            Cancel
          </Button>
        </ModalFooter>
      </CustomModal>
      {/* Successfully assigned modal (not first time) */}
      {/* We will add the logic for these two modals later but for now I just wanted to get the styling right */}
      <CustomModal
        isOpen={isSuccessVisible}
        onClose={handleComplete}
        modalType="success"
      >
        <ModalBody p="0px" marginBottom="30px">
          <Text
            align="center"
            fontSize="smallTitle"
            lineHeight="medText"
            fontWeight="smallText"
          >
            Card has been assigned!
          </Text>
        </ModalBody>
        <ModalFooter justifyContent={'center'} p="0">
          <Button
            variant="text"
            fontWeight="smallText"
            onClick={handleComplete}
          >
            Close
          </Button>
        </ModalFooter>
      </CustomModal>
      <Box display="flex" flexDir="column" alignItems="center">
        <Box className={styles.divider} />
        {isLoading ? <ActivityIndicator tip="Working" /> : null}
        {gift && (
          <Box>
            <ItemWithInfo
              infoText={
                <>
                  <Text>
                    This gift card needs to be assigned! Click on your
                    child&apos;s profile below to post and invest their card.
                  </Text>
                </>
              }
              anchorComponent={
                <Heading variant="title" mb="0">
                  Assign Gift Card
                </Heading>
              }
              questionMarkSize="md"
              analyticsSrc="Gift Assign Screen"
              popoverContentProps={{
                borderWidth: '5px',
                borderColor: 'brand.titleHighlight',
              }}
              justifyContent="center"
            />
            <Box
              borderWidth="6px"
              borderBlock="solid"
              borderColor="brand.grey50"
              boxShadow="0px 5px 55px var(--chakra-colors-brand-popoverShadow)"
              backdropFilter="blur(6px)"
              px="50px"
              paddingTop="150px"
              paddingBottom="30px"
              position="relative"
              dropShadow="brand.popoverShadow"
              maxW="345px"
              borderRadius="20px"
              marginBottom="30px"
              marginTop={`${assignCardMarginTop()}px`}
            >
              {UnassignedGiftCardImages}
              <Box
                w="245px"
                // p="10px"
                className={styles.giftCardInfo}
                // borderRadius="lg"
                // mb="5"
                // borderWidth="1px"
                fontSize={'21px'}
                fontWeight={'400'}
                lineHeight={'26px'}
                // borderStyle={'solid'}
                display="flex"
                flexDir="column"
                gap="10px"
              >
                <Box>
                  <b>To:</b> {gift.childName}
                </Box>
                <Box>
                  <b>From:</b> {gift.senderName}
                </Box>
                <Box>
                  <b>Amount:</b> ${gift.giftCardAmt}
                </Box>
                <Box>
                  <b>Message:</b> &quot;{gift.message}&quot;
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {(children || []).map((kid: any) => (
          <KidSection isEnable={!!giftId} kid={kid} key={kid.id} />
        ))}

        <Box
          mt="20px"
          mb="50px"
          alignContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <Button
            data-testid="add_button"
            onClick={onClickAddChild}
            // leftIcon={<AddIcon />}
            variant="action"
            colorScheme="accent"
          >
            ADD A KID
          </Button>
          <AddChildText parentsChildren={children} />
        </Box>
      </Box>
      {confirmLoading && <ActivityIndicator tip="Working" />}
    </Container>
  );
}

export default GiftAssignScreen;
