import React, { useEffect, useState } from 'react';
import { Image, ImageProps } from '@chakra-ui/react';

interface GiftCardImageProps {
  giftCardImageSrc: string;
  size: 'lg' | 'sm' | 'custom';
  // width?: string | string[];
  // height?: string | string[];
  // radius?: string | string[];
  // borderPixels?: string | string[];
  imageProps?: ImageProps;
  isRotated?: boolean;
}

const GiftCardImage = ({
  giftCardImageSrc,
  size,
  // width,
  // height,
  // radius,
  // borderPixels,
  imageProps,
  isRotated,
}: GiftCardImageProps): React.ReactElement => {
  const [defaultWidth, setDefaultWidth] = useState<string | string[]>();
  const [defaultHeight, setDefaultHeight] = useState<string | string[]>();
  const [defaultRadius, setDefaultRadius] = useState<string | string[]>();
  const [defaultBorderPixels, setDefaultBorderPixels] = useState<
    string | string[]
  >('');

  useEffect(() => {
    switch (size) {
      case 'lg': {
        setDefaultWidth('280px');
        setDefaultHeight('176.09px');
        setDefaultRadius('26px');
        setDefaultBorderPixels('4px');
        break;
      }
      case 'sm': {
        setDefaultWidth('112px');
        setDefaultHeight('71px');
        setDefaultRadius('13px');
        setDefaultBorderPixels('2.5px');
        break;
      }
      default: {
        // if (
        //   imageProps?.width &&
        //   imageProps?.height &&
        //   imageProps?.borderRadius &&
        //   imageProps?.borderWidth
        // ) {
        //   setDefaultWidth(imageProps.width);
        //   setDefaultHeight(imageProps.height);
        //   setDefaultRadius(imageProps.borderRadius);
        //   setDefaultBorderPixels(imageProps.borderWidth);
        // }
        break;
      }
    }
  }, [size]);

  if (size === 'custom') {
    return (
      <Image
        src={giftCardImageSrc}
        alt="Gift Card"
        transform={isRotated ? 'rotate(-2.93deg)' : 'none'}
        boxShadow=" 0px 2.59px 2.16228px 1.29737px var(--chakra-colors-brand-shadow)"
        border={
          imageProps?.borderWidth
            ? `${imageProps.borderWidth} solid var(--chakra-colors-brand-primaryBackground)`
            : ''
        }
        willChange="transform"
        {...imageProps}
      />
    );
  }
  return (
    <Image
      src={giftCardImageSrc}
      alt="Gift Card"
      w={defaultWidth}
      h={defaultHeight}
      borderRadius={defaultRadius}
      transform={isRotated ? 'rotate(-2.93deg)' : 'none'}
      boxShadow=" 0px 2.59px 2.16228px 1.29737px var(--chakra-colors-brand-shadow)"
      border={`${defaultBorderPixels} solid var(--chakra-colors-brand-primaryBackground)`}
      willChange="transform"
      // boxSizing="border-box"
    />
  );
};

export default GiftCardImage;
